// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth"

import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";

import {getMessaging} from "firebase/messaging"

// configuración de firebase produccion
const firebaseConfig = {

  apiKey: "AIzaSyDG9eAM9B2yQ0-Ct2w0IYXDJMVRF9dJyrA",

  authDomain: "control-de-cubas.firebaseapp.com",

  projectId: "control-de-cubas",

  storageBucket: "control-de-cubas.appspot.com",

  messagingSenderId: "255356903722",

  appId: "1:255356903722:web:92979bf550f3c372b83e53"

};
// configuración de firebase pruebas
/*const firebaseConfig = {

  apiKey: "AIzaSyCrQGrNukWxeR3SgigasJf64jR9MMbRg30",

  authDomain: "control-cubas-pruebas.firebaseapp.com",

  projectId: "control-cubas-pruebas",

  storageBucket: "control-cubas-pruebas.appspot.com",

  messagingSenderId: "159704848082",

  appId: "1:159704848082:web:507ec0e04f2536b8903f0b"

};*/




// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const storage = getStorage(app)

export const messaging = getMessaging(app)