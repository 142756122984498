import { useEffect, useState } from "react";
import { Sidebar, Center, Topbar } from "../components/index";
import { ChatWindow } from "../components/chat/chat.js";
import "../css/home.scss";
import { useAuth } from "../context/authContext";
import { useStore } from "../store/appStore.js";

export function Main() {
  const [menu, setMenu] = useState("");
  const [auth,setAuth] = useState(true);//en true puentea la licencia
  const { loadLicencias } = useStore();
  const [licencia, setLicencia] = useState();
  const { user } = useAuth();
  useEffect(() => {
    async function licence() {
      const licencia = await loadLicencias(user.email, user.photoURL);
      setLicencia(licencia);
    }
    licence();
  }, []);
  useEffect(() => {
    if (licencia?.length>0) setAuth(true)
  }, [licencia]);

  const sendMenu = (menuSend) => {
    setMenu(menuSend);
  };

  return (
    <div className="home">
      <Topbar className="topbar" data={menu} />
      {auth ? (
        <div className="principal">
          <Sidebar className="sidebar" data={menu} sendMenu={sendMenu} />
          <Center className="center" />
        </div>
      ) : (
        <div className="cartel-licencia">Licencia no válida</div>
      )}
      {user.email === "siles@sistelin.es" && auth ? <ChatWindow /> : null}
    </div>
  );
}
