import { Route, Routes } from "react-router-dom";
import { Main, Login } from "./screens/index";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./context/authContext";
import "./css/form.scss";
import { ToastContainer } from "react-toastify";
import { ListadoCliente, FormClientes } from "./components/clientes/index";
import { ListadoCubas, FormCubas } from "./components/cubas/index";
import Mapa from "./components/mapa/mapa";
import ListadoLogs from "./components/logs/ListadoLogs";
import "react-toastify/dist/ReactToastify.css";

import {
  ListadoServicios,
  FormServicios,
  NuevoServicio,
} from "./components/servicios";
import Calendario from "./components/calendario/calendario";
import moment from "moment";
import { auth, db } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import ListadoOfertas from "./components/ofertas/ListadoOfertas";
import FormOfertas from "./components/ofertas/FormOfertas";

export default function App() {
  setInterval(() => {
    const unsubuscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser!==null) {
        const salida = moment().format("DD/MM/YYYY HH:mm"); // Obtiene la fecha actual como cadena de texto en formato "MM/DD/AAAA"
        try{
        updateDoc(doc(db, "logs", currentUser.refreshToken), { salida });
        }catch(e){
          console.log(e)
        }
      }
    });
  }, 60000);
  return (
    <div id="root" style={{ height: "100%" }}>
      <AuthProvider>
        <ToastContainer style={{ marginTop: "60px" }} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/*" element={<Main />}>
              <Route path="clientes/*" element={<ListadoCliente />} />
              <Route
                path="clientes/nuevo"
                element={<FormClientes limpiar={true} />}
              />
              <Route path="clientes/listado" element={<ListadoCliente />} />
              <Route path="clientes/listado/:id" element={<FormClientes />} />
              <Route path="cubas/*" element={<ListadoCubas />} />
              <Route path="cubas/nuevo" element={<FormCubas />} />
              <Route path="cubas/listado" element={<ListadoCubas />} />
              <Route path="cubas/listado/:idCubas" element={<FormCubas />} />
              <Route path="servicios/*" element={<ListadoServicios />} />
              <Route path="servicios/nuevo" element={<NuevoServicio />} />
              <Route path="servicios/listado" element={<ListadoServicios />} />
              <Route
                path="servicios/listado/:idServicio"
                element={<FormServicios />}
              />
              <Route path="mapa" element={<Mapa />} />
              <Route path="calendario" element={<Calendario />} />
              <Route path="log" element={<ListadoLogs />} />
              <Route path="ofertas/*" element={<FormOfertas />} />
              <Route
                path="ofertas/nuevo"
                element={<FormOfertas limpiar={true} />}
              />
              <Route path="ofertas/listado" element={<ListadoOfertas />} />
              <Route path="ofertas/listado/:idOferta" element={<FormOfertas />} />
            </Route>
          </Route>
          <Route path="*" element={<h1>no found</h1>} />
        </Routes>
      </AuthProvider>
    </div>
  );
}
