import React, { useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es"; // Agrega el módulo de idioma español para moment
import "../../App.css";
import moment from "moment";
import es from "date-fns/locale/es"; // Importa el módulo de idioma español para date-fns
import { useStore } from "../../store/appStore";


const localizer = momentLocalizer(moment);
moment.locale("es", es); // Configura el idioma español para moment

class CustomAgenda extends React.Component {
  render() {
    const { events } = this.props;

    return (
      <div>
        {events.map((event) => (
          <div key={event.id}>
            <p>Servicio: {event.title}</p>
            <p>Fecha de entrega: {event.start.toLocaleString()}</p>
            <p>Fecha de recogida: {event.end.toLocaleString()}</p>
          </div>
        ))}
        
      </div>
    );
  }
}
function Calendario() {
  const { loadServicios, serviciosStore } = useStore();
  const [eventos, setEventos] = useState();
  const data = useMemo(() => serviciosStore, [serviciosStore]);
  useEffect(() => {
    loadServicios().then(() => {
      const eventosRenombrados = data.map(
        (evento) => (
          {
            title: `Cuba ${evento.tipoCuba} para ${evento.nombreCliente}`,
            start: moment.unix(evento?.dateTimeDelivery?.seconds).toDate(),
            end: moment.unix(evento?.dateTimePick?.seconds).toDate(),
            desc: "des",
            location: "location",
          }
        )
      );
      setEventos(eventosRenombrados);
    });
  }, []);

  useEffect(() => {
    console.log(eventos);
  }, [eventos]);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: '#d19f26'
    };
    return {
      style
    };
  };

  if (eventos){
  console.log(eventos)
    return (
      <div className="app-container">
        <div className="selector-container"></div>
        <div className="calendar-container">
          <Calendar
            localizer={localizer}
            events={eventos}
            components={{
              agenda: {
                event: ({ event }) => <CustomAgenda events={[event]} />,
              },
            }}
            eventPropGetter={eventStyleGetter}
            startAccessor="start"
            endAccessor="end"
            views={['month','week']}
            messages={{
              next: "Siguiente",
              previous: "Anterior",
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día",
              event: "Evento",
              time: "Horario",
              date: "Fecha",
              noEventsInRange:
                "No hay ni entregas ni recogidas en la fecha señalada",
            }}
          />
        </div>
      </div>
    );
          }else 
    return "Cargando"
}

export default Calendario;
