
import logo from "../../img/camion.svg";
export default function Cargando() {
    return (
        <div style={{ "display": 'flex', 'justify-content': 'center', 'align-items': 'center', 'height': '100vh', 'flexDirection': 'column' }}>
            <img className="circle"  style={{width:'250px'}} src={logo} alt="Logo" />

            <h1>Cargando</h1>
        </div>
    );
}
