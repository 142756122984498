import React, { useEffect } from "react";
import { useMemo } from "react";
import { useStore } from "../../store/appStore";
import { Table } from "../table";
import moment from "moment";


export default function ListadoOfertas() {
  const {loadOfertas,ofertasStore} = useStore();
  const data = useMemo(() => ofertasStore, [ofertasStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Cliente", accessor: "nombreCliente" },
      {
        Header: "Fecha de entrega",
        accessor: "dateTimeDelivery",
        Cell: ({ value }) =>
          value ? moment(value.toDate()).format("DD-MM-YYYY") : null,
      },
      {
        Header: "Fecha de entrega",
        accessor: "dateTimePick",
        Cell: ({ value }) =>
          value ? moment(value.toDate()).format("DD-MM-YYYY") : null,
      },
      { Header: "Precio", accessor: "precio" },
    ],
    []
  );
  useEffect(() => {
    loadOfertas()
  }, []);
  return <Table columns={columns} data={data} />;
}
