import { useState } from "react";
import { useAuth } from "../context/authContext";
import { GiExitDoor, GiHamburgerMenu } from "react-icons/gi";
import "../css/sidebar.scss";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/appStore";
import ReactModal from "react-modal";

export const Sidebar = (props) => {
  const { limpiarCurrent } = useStore();
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const [expander, setExpander] = useState(true);
  const { logout, user } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  //logout de la app
  const handleLogout = async () => {
    await logout();
  };
  //expander o contraer el menu
  const expanderAction = () => {
    setExpander((current) => !current);
  };
  //enviamos a home el menu selecionado
  const enviar = (menu) => {
    navigate("/" + menu + "/listado");
    props.sendMenu(menu);
  };
  return (
    <div
      onClick={() => limpiarCurrent()}
      style={{
        height:
          props.data === "" || props.data === "mapa"
            ? "calc(100vh - 65px)"
            : "calc(100vh - 89px)",
      }}
      className={expander ? "sidebar" : "Sidebar collapsed"}
    >
      <div className="menu">
        <div className="item hamburger-menu" onClick={expanderAction}>
          <div className="itemoicon">
            <GiHamburgerMenu className="icons" />
          </div>
          <div className="itemtext">Menu</div>
        </div>
        <div
          className={selected === "clientes" ? "item menuActive" : "item "}
          onClick={() => {
            setSelected("clientes");
            enviar("clientes");
          }}
        >
          <div className="itemoicon">
            <div className="icons icon-clientes"></div>
          </div>
          <div className="itemtext">Clientes</div>
        </div>
        {user.email === "siles@sistelin.es" ? (
          <div
            className={selected === "ofertas" ? "item menuActive" : "item "}
            onClick={() => {
              setSelected("ofertas");
              enviar("ofertas");
            }}
          >
            <div className="itemoicon">
              <div className="icons icon-ofertas"></div>
            </div>
            <div className="itemtext">Ofertas</div>
          </div>
        ) : null}
        <div
          className={selected === "cubas" ? "item menuActive" : "item "}
          onClick={() => {
            setSelected("cubas");
            enviar("cubas");
          }}
        >
          <div className="itemoicon">
            <div className="icons icon-proveedores"></div>
          </div>
          <div className="itemtext">Cubas</div>
        </div>
        <div
          className={selected === "servicios" ? "item menuActive" : "item "}
          onClick={() => {
            setSelected("servicios");
            enviar("servicios");
          }}
        >
          <div className="itemoicon">
            <div className="icons icon-agentes"></div>
          </div>
          <div className="itemtext">Servicios</div>
        </div>
        <div
          className={selected === "mapas" ? "item menuActive" : "item "}
          onClick={() => {
            setSelected("mapas");
            props.sendMenu("mapa");
            navigate("/mapa");
          }}
        >
          <div className="itemoicon">
            <div className="icons icon-map"></div>
          </div>
          <div className="itemtext">Mapas</div>
        </div>
        {user.email === "siles@sistelin.es" ? (
          <div
            className={selected === "calendario" ? "item menuActive" : "item "}
            onClick={() => {
              setSelected("calendario");
              props.sendMenu("calendario");
              navigate("/calendario");
            }}
          >
            <div className="itemoicon">
              <div className="icons icon-calendario"></div>
            </div>
            <div className="itemtext">Calendario</div>
          </div>
        ) : null}
        {user.email === "siles@sistelin.es" ? (
          <div
            className={selected === "log" ? "item menuActive" : "item "}
            onClick={() => {
              setSelected("log");
              props.sendMenu("log");
              navigate("/log");
            }}
          >
            <div className="itemoicon">
              <div className="icons icon-logs"></div>
            </div>
            <div className="itemtext">Logs</div>
          </div>
        ) : null}
      </div>

      <div>
        <div className="item logout" onClick={handleLogout}>
          <div className="itemoicon">
            <GiExitDoor className="icons" />
          </div>
          <div className="itemtext">Logout</div>
        </div>
        <div class="item logout">
          <div
            class="itemtext"
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            onClick={() => setIsOpen(true)}
          >
            <div>CubaGest 1.5</div>
          </div>
        </div>
      </div>
    </div>
  );
};
