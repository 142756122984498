import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import Cargando from "../cargando/cargando";

export default function FormOfertas(props) {
  const { insert } = useAuth();
  const [cargando, setCargando] = useState(true);
  const { idOferta } = useParams();
  const {} = useStore();
  const {
    clientesStore,
    loadClientes,
    setOferta,
    cargarCliente,
    cargarOferta,
    ofertaCurrent,
    clienteCurrent,
    cubasStore,
    loadCubas,
  } = useStore();
  const navigate = useNavigate();
  const handleChange = ({ target: { value, name } }) => {
    if (name === "precio") {
      value = value.replace(/[^\d,]/g, "");
    }
    setOferta({ ...ofertaCurrent, [name]: value });
  };
  //
  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        console.log(ofertaCurrent);
        if (ofertaCurrent.idCliente) {
          try {
            const idCubas = await insert("ofertas", ofertaCurrent);
            navigate(`/ofertas/listado/${idCubas}`);
          } catch (err) {}
        } else {
          showToast("error", "Debes de seleccionar un cliente");
        }
        break;
      default:
        break;
    }
  };
  async function datosCliente() {
    if (ofertaCurrent === null) {
      showToast("error", "El cliente no existe");
      navigate("/clientes/listado");
    } else {
      try {
        await cargarCliente(ofertaCurrent.idCliente);
      } catch (error) {
        // Manejo de errores
      }
    }
  }
  useEffect(() => {
    if (!clienteCurrent?.localidad) datosCliente();
  }, [ofertaCurrent]);
  async function datos() {
    await loadClientes();
    await loadCubas();
    if (idOferta) {
      await cargarOferta(idOferta);
      if (ofertaCurrent === null) {
        showToast("error", "El cliente no existe");
        navigate("/ofertas/listado");
      }
      console.log(ofertaCurrent);
    }

    setCargando(false);
  }
  useEffect(() => {
    datos();
    setCargando(false);
  }, []);
  if (cargando) {
    <Cargando />;
  } else {
    return (
      <>
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="superior">
              <label className="tituloVentana">Añadir Oferta</label>
              <div className="buttonMain">
                <input
                  className="button main"
                  type="submit"
                  value="Añadir"
                  onClick={handleSubmit}
                />
              </div>
            </div>
            <div className={"enabled"}>
              <label
                className="label"
                style={{ fontSize: "20px", fontWeight: "bolder" }}
              >
                Datos de cliente
              </label>
              <div className="datos">
                <div className="columna">
                  <div className="grupo">
                    <label htmlFor="" className="label">
                      Cliente
                    </label>
                    <div className="grupoSelect">
                      <Select
                        className="select"
                        classNamePrefix="selectClass"
                        isSearchable={true}
                        value={
                          ofertaCurrent?.idCliente
                            ? clientesStore.find(
                                (cliente) =>
                                  cliente.id === ofertaCurrent?.idCliente
                              )
                            : null
                        }
                        options={clientesStore}
                        getOptionLabel={(cliente) => cliente.nombre}
                        getOptionValue={(cliente) => cliente.id}
                        onChange={(val) => {
                          cargarCliente(val.id);
                          setOferta({
                            ...ofertaCurrent,
                            idCliente: val.id,
                            nombreCliente: val.nombre,
                            mailCliente: val.mail ? val.mail : null,
                            nombreContactoDelivery: "",
                            aceptado: false,
                            facturado: false,
                            activada: false,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="grupo">
                    <label className="label">Cif</label>
                    <label className="label">
                      {clienteCurrent?.cif
                        ? clienteCurrent?.cif
                        : ofertaCurrent?.idCliente}
                    </label>
                  </div>
                  <div className="grupo">
                    <label className="label">Nombre</label>
                    <label className="label">
                      {clienteCurrent?.nombre
                        ? clienteCurrent?.nombre
                        : ofertaCurrent?.nombreCliente}
                    </label>
                  </div>
                  <div className="grupo">
                    <label className="label">Telefono</label>
                    <label className="label">{clienteCurrent?.tlf}</label>
                  </div>
                  <div className="grupo">
                    <label className="label">E-Mail</label>
                    <label className="label">{clienteCurrent?.mail}</label>
                  </div>
                </div>
                <div className="columna">
                  <div className="grupo">
                    <label className="label">Direccion</label>
                    <label className="label">{clienteCurrent?.direccion}</label>
                  </div>
                  <div className="grupo">
                    <label className="label">Localidad</label>
                    <label className="label">{clienteCurrent?.localidad}</label>
                  </div>
                  <div className="grupo">
                    <label className="label">Provincia</label>
                    <label className="label">{clienteCurrent?.provincia}</label>
                  </div>
                  <div className="grupo">
                    <label className="label">Código postal</label>
                    <label className="label">{clienteCurrent?.cp}</label>
                  </div>
                </div>
              </div>
              <label
                className="label"
                style={{ fontSize: "20px", fontWeight: "bolder" }}
              >
                Datos del Oferta
              </label>
              <div className="datos">
                <div className="columna">
                  <div className="grupo">
                    <label htmlFor="" className="label">
                      Cuba
                    </label>
                    <div className="grupoSelect">
                      <Select
                        className="select"
                        classNamePrefix="selectClass"
                        isSearchable={true}
                        value={
                          ofertaCurrent?.idCuba
                            ? cubasStore.find(
                                (cuba) => cuba.id === ofertaCurrent?.idCuba
                              )
                            : null
                        }
                        options={cubasStore}
                        getOptionLabel={(cuba) => cuba.tamaño}
                        getOptionValue={(cuba) => cuba.id}
                        onChange={(val) => {
                          setOferta({
                            ...ofertaCurrent,
                            idCuba: val.id,
                            tipoCuba: val.tamaño,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="grupo">
                    <label className="label">Precio</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="Precio de la ofertaCurrent"
                      name="precio"
                      id="precio"
                      onChange={handleChange}
                      value={ofertaCurrent?.precio ? ofertaCurrent?.precio : 0}
                    />
                  </div>
                  <div className="grupo">
                  <label className="label">Aceptado</label>
                  <div className="text switchText">
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={ofertaCurrent.aceptado}
                        checked={ofertaCurrent.aceptado}
                        onClick={() => {
                          ofertaCurrent.aceptado = !ofertaCurrent.aceptado;
                          setOferta({
                            ...ofertaCurrent,
                            aceptado: ofertaCurrent.aceptado,
                          });
                        }}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                </div>
                </div>
                <div className="columna">
                  <div className="grupo">
                    <label className="label">Fecha de entrega</label>
                    <ReactDatePicker
                      dateFormat="dd/MM/yyyy HH:mm"
                      locale="es"
                      placeholderText="Fecha de la entrega"
                      selected={
                        ofertaCurrent?.dateTimeDelivery
                          ? typeof ofertaCurrent?.dateTimeDelivery ===
                              "string" ||
                            ofertaCurrent?.dateTimeDelivery instanceof Date
                            ? ofertaCurrent?.dateTimeDelivery
                            : ofertaCurrent?.dateTimeDelivery.toDate()
                          : null
                      }
                      onChange={(dateTimeDelivery) =>
                        setOferta({
                          ...ofertaCurrent,
                          dateTimeDelivery,
                        })
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Hora"
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">Fecha de recogida</label>
                    <ReactDatePicker
                      dateFormat="dd/MM/yyyy HH:mm"
                      locale="es"
                      placeholderText="Fecha de la recogida"
                      selected={
                        ofertaCurrent?.dateTimePick
                          ? typeof ofertaCurrent?.dateTimePick === "string" ||
                            ofertaCurrent?.dateTimePick instanceof Date
                            ? ofertaCurrent?.dateTimePick
                            : ofertaCurrent?.dateTimePick.toDate()
                          : null
                      }
                      onChange={(dateTimePick) =>
                        setOferta({
                          ...ofertaCurrent,
                          dateTimePick,
                        })
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Hora"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
