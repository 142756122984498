import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase";
import {
  collection,
  addDoc,
  where,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import "../../css/chat.css";
export const ChatWindow = () => {
  const room = "publico";
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesRef = collection(db, "messages");
  const [minimized, setMinimized] = useState(true);

  const toggleMinimize = () => {
    setMinimized(!minimized);
  };

  useEffect(() => {
    const queryMessages = query(
      messagesRef,
      where("room", "==", room),
      orderBy("createdAt")
    );
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let messages = [];
      snapshot.forEach((doc) => {
        messages.push({ ...doc.data(), id: doc.id });
      });
      console.log(messages);
      setMessages(messages);
    });

    return () => unsuscribe();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newMessage === "") return;
    await addDoc(messagesRef, {
      text: newMessage,
      createdAt: serverTimestamp(),
      user: auth.currentUser.email.substring(
        0,
        auth.currentUser.email.indexOf("@")
      ),
      room,
    });

    setNewMessage("");
  };

  return (
    <div className={`chat-window${minimized ? " minimized" : ""}`}>
      <div className="header" onClick={toggleMinimize}>
        Mensajes
      </div>
      {!minimized && (
        <div className="content">
          <div className="content">
            {messages.map((message) => (
              <div key={message.id} className="message">
              <span className="user">{message.user}:</span> {message.text}
            </div>
            ))}
          </div>
          <div className="footer">
            <form onSubmit={handleSubmit} >
              <input
                type="text"
                value={newMessage}
                onChange={(event) => setNewMessage(event.target.value)}
                className="new-message-input"
                placeholder="Escribe aqui el mensaje al grupo"
              />
              <button type="submit" className="send-button">
                Enviar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
