import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useEffect, useState } from "react";
import { useStore } from "../../store/appStore";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import iconCubaNaranja from "../../img/cubaNaranja.svg";
import iconCubaVerde from "../../img/cubaVerde.svg";
import iconCubaRoja from "../../img/cubaRoja.svg";
import iconOficina from "../../img/oficina.svg";
import Select from "react-select";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Map = () => {
  const navigate = useNavigate();
  const { cubasStore, loadCubas, loadServicios, serviciosStore } = useStore();
  const [cubas, setCubas] = useState([0, 0, 0]);
  const [conteoPorTipoYNivel, setConteoPorTipoYNivel] = useState({});
  const [tipoCuba, setTipoCuba] = useState();
  const [filtroCuba, setFiltroCuba] = useState({
    label: "Todas",
    value: "Todas",
  });
  const cubaNaranja = new L.Icon({
    iconUrl: iconCubaNaranja,
    iconRetinaUrl: iconCubaNaranja,
    popupAnchor: [-0, -0],
    iconSize: [119, 32],
  });
  const cubaVerde = new L.Icon({
    iconUrl: iconCubaVerde,
    iconRetinaUrl: iconCubaVerde,
    popupAnchor: [-0, -0],
    iconSize: [119, 32],
  });
  const cubaRoja = new L.Icon({
    iconUrl: iconCubaRoja,
    iconRetinaUrl: iconCubaRoja,
    popupAnchor: [-0, -0],
    iconSize: [119, 32],
  });
  const oficina = new L.Icon({
    iconUrl: iconOficina,
    iconRetinaUrl: iconOficina,
    popupAnchor: [-0, -0],
    iconSize: [119, 32],
  });
  useEffect(() => {
    loadServicios();
    loadCubas();
  }, []);

  useEffect(() => {
    // Calcular los contadores de los colores cuando serviciosStore cambie
    const counters = [0, 0, 0, 0];
    const nivelesDias = [
      { minDias: -1, maxDias: 6, nivel: "< 5 días", color: "green" },
      { minDias: 5, maxDias: 10, nivel: "5 - 9 días", color: "orange" },
      { minDias: 9, maxDias: Infinity, nivel: "> 9 días", color: "red" },
    ];
    const tiposUnicos = [
      ...new Set(
        serviciosStore
          .filter((servicio) => servicio.nombreContactoPick === "")
          .map((servicio) => servicio.tipoCuba)
      ),
    ];
    const conteoPorTipoYNivel = tiposUnicos.reduce((conteo, tipo) => {
      console.log(tipo);
      conteo[tipo] = nivelesDias.reduce((conteoPorNivel, nivel) => {
        const cantidadElementos = serviciosStore.filter((elemento) => {
          if (elemento.dateTimeDelivery)
          return (
            elemento.nombreContactoPick === "" &&
            elemento.tipoCuba === tipo &&
            moment()
              .startOf("day")
              .diff(elemento.dateTimeDelivery.toDate(), "days") <
              nivel.maxDias &&
            nivel.minDias <
              moment()
                .startOf("day")
                .diff(elemento.dateTimeDelivery.toDate(), "days")
          );
        }).length;
        console.log(cantidadElementos);
        conteoPorNivel[nivel.nivel] = cantidadElementos;
        return conteoPorNivel;
      }, {});

      return conteo;
    }, {});
    setConteoPorTipoYNivel(conteoPorTipoYNivel);
    const opcionesTipoCuba = tiposUnicos.map((tipo) => ({
      label: tipo,
      value: tipo,
    }));
    opcionesTipoCuba.push({
      label: "Todas",
      value: "Todas",
    });
    setTipoCuba(opcionesTipoCuba);
    serviciosStore
      .filter((servicio) => servicio.nombreContactoPick === "")
      .forEach((servicio) => {
        if (servicio.dateTimeDelivery) {
          const dias = moment()
            .startOf("day")
            .diff(servicio.dateTimeDelivery.toDate(), "days");
          if (dias < 6) {
            counters[0]++;
          } else if (dias < 9) {
            counters[1]++;
          } else {
            counters[2]++;
          }
        }
      });
    cubasStore.map((cuba) => {
      counters[3] = counters[3] + parseInt(cuba.cantidad);
    });
    setCubas(counters);
  }, [serviciosStore]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "66px",
          left: "50%",
          transform: "translate(-50%, 0%)",
          background: "white",
          padding: "10px",
          zIndex: 1000,
        }}
      >
        {conteoPorTipoYNivel && Object.keys(conteoPorTipoYNivel).length > 0 ? (
          <div className="grupo">
            <label className="label">Seleccione las cubas a visualizar</label>
            <div className="grupoSelect">
              <Select
                className="select"
                classNamePrefix="selectClass"
                value={filtroCuba}
                options={tipoCuba}
                onChange={(val) => setFiltroCuba(val)}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          position: "absolute",
          top: "66px",
          right: "10px",
          background: "white",
          padding: "10px",
          zIndex: 1000,
        }}
      >
        {conteoPorTipoYNivel && Object.keys(conteoPorTipoYNivel).length > 0 ? (
          <Table data={conteoPorTipoYNivel} cubas={cubas} />
        ) : null}
      </div>
      <MapContainer
        center={[37.4755904, -5.6552585]}
        zoom={11}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {serviciosStore
          .filter((servicio) => {
            if (filtroCuba.value === "Todas") {
              return servicio.nombreContactoPick === "";
            } else {
              return (
                servicio.nombreContactoPick === "" &&
                servicio.tipoCuba === filtroCuba.value
              );
            }
          })
          .map((servicio) => {
            let icon;
            let dias = moment()
              .startOf("day")
              .diff(servicio.dateTimeDelivery.toDate(), "days");
            switch (true) {
              case dias < 5:
                icon = cubaVerde;
                break;
              case dias < 9:
                icon = cubaNaranja;
                break;
              default:
                icon = cubaRoja;
                break;
            }
            console.log("2", servicio);
            return (
              <Marker
                position={[servicio.latitud, servicio.longitud]}
                key={servicio.id}
                icon={icon}
              >
                <Popup>
                  <div onDoubleClick={()=>
                    navigate(`../servicios/listado/${servicio.id}`)
                  }>
                    <label>Nombre: {servicio.nombreCliente}<br/>
                    Tipo cuba: {servicio.tipoCuba}<br/>
                    Nombre contacto: {servicio.nombreContactoDelivery}<br/>
                    Dirección: {servicio.direContactoDelivery}<br/>
                    Teléfono: {servicio.tlfContactoDelivery}<br/>
                    
                      Fecha entrega:
                      {moment(servicio.dateTimeDelivery.toDate()).format(
                        "DD-MM-DD"
                      )}
                    </label>
                  </div>
                </Popup>
              </Marker>
            );
          })}
        {/*<Marker position={[37.4825206, -5.6518394]} icon={oficina}>
          <Popup>
            <div>
              <h2>Oficina</h2>
            </div>
          </Popup>
        </Marker>*/}
      </MapContainer>
    </>
  );
};

const Table = ({ data, cubas }) => {
  const tipos = Object.keys(data);
  const niveles = Object.keys(data[tipos[0]]);
  return (
    <table style={{ border: "1px solid black", borderCollapse: "collapse" }}>
      {" "}
      <thead>
        <tr>
          <th style={{ border: "1px solid black", padding: "8px" }}> Tipo</th>
          {niveles.map((nivel) => (
            <th
              style={{ border: "1px solid black", padding: "8px" }}
              key={nivel}
            >
              {nivel}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tipos.map((tipo) => (
          <tr key={tipo}>
            <td style={{ border: "1px solid black", padding: "4px" }}>
              {tipo}
            </td>
            {niveles.map((nivel) => (
              <td
                style={{
                  border: "1px solid black",
                  padding: "4px",
                  color:
                    nivel === "< 5 días"
                      ? "green"
                      : nivel === "5 - 9 días"
                      ? "orange"
                      : "red",
                }}
                key={nivel}
              >
                {data[tipo][nivel]}
              </td>
            ))}
          </tr>
        ))}
        <tr>
          <td
            style={{ border: "1px solid black", padding: "4px" }}
            key={"total"}
          >
            Total:
          </td>
          <td
            style={{
              border: "1px solid black",
              padding: "4px",
              color: "green",
            }}
          >
            {cubas[0]}
          </td>
          <td
            style={{
              border: "1px solid black",
              padding: "4px",
              color: "orange",
            }}
          >
            {cubas[1]}
          </td>
          <td
            style={{ border: "1px solid black", padding: "4px", color: "red" }}
          >
            {cubas[2]}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default Map;
