import "../css/topbar.scss";
import { useAuth } from "../context/authContext";
import { useStore } from "../store/appStore";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import {
  AiOutlineCloseCircle,
  AiOutlineSave,
} from "react-icons/ai";

import { useState } from "react";
export const Topbar = (props) => {
  const { limpiarCurrent } = useStore();
  const [visible, setVisible] = useState(false);
  const { user,  updateCurrentProfile } = useAuth();

  const [nombre, setNombre] = useState(user.displayName);
  const [licencia, setLicencia] = useState(user.photoURL)
  const save = async () => {
    await updateCurrentProfile(nombre,licencia);
    closeProfile();
    window.location.reload()
  };
console.log('usuario',user)
  function closeProfile() {
    setVisible(false);
  }
  return (
    <div className="topbar shadow-md ">
      <div className="logo-user">
        <div
          onClick={() => {
            setVisible(true);
          }}
          className="user"
        >
          <span>{user.displayName ? user.displayName : user.email}</span>
        </div>
      </div>
      <Modal
        isOpen={visible}
        onRequestClose={closeProfile}
        style={{
          overlay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "8px",
          },
          content: {
            border: "0px",
            background: "white",
            maxWidth: "100%",
          },
        }}
      >
        <AiOutlineCloseCircle
          size={"1.5em"}
          color="#CB950E"
          style={{
            marginTop: "25px",
            position: "absolute",
            right: "20px",
          }}
          onClick={closeProfile}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Centrar tanto horizontal como verticalmente
            justifyContent: "center", // Centrar tanto horizontal como verticalmente
            height: "100%",
            padding: "20px",
            border: "1px",
          }}
          className="datos columna"
        >
          <div
            className="grupo"
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Modificar el perfil de usuario
          </div>
          <div className="grupo" style={{ width: "50%", display: "flex" }}>
            <label className="label" style={{ flex: 2 }}>
              Nombre del usuario
            </label>
            <input
              type="text"
              style={{ flex: 3, marginRight: "10px" }}
              className="text"
              placeholder="Nombre del usuario"
              name="nombre"
              id="nombre"
              onChange={(val) => setNombre(val.target.value)}
              value={nombre}
            />
          </div>
          <div className="grupo" style={{ width: "50%", display: "flex" }}>
            <label className="label" style={{ flex: 2 }}>
              Licencia de uso
            </label>
            <input
              type="text"
              style={{ flex: 3, marginRight: "10px" }}
              className="text"
              placeholder="Nombre del usuario"
              name="licencia"
              id="licencia"
              onChange={(val) => setLicencia(val.target.value)}
              value={licencia}
            />
          </div>

          <button
            className="grupo"
            style={{ display: "flex" }}
            onClick={() => save()}
          >
            <label style={{ color: "#CB950E", marginRight: "20px" }}>
              Guardar
            </label>
            <AiOutlineSave size={"1.5em"} color="#CB950E" />
          </button>
        </div>
      </Modal>
      <div className="menu" onClick={() => limpiarCurrent()}>
        {props.data === "clientes" ||
        props.data === "cubas" ||
        props.data === "servicios" ||
        props.data === "ofertas" ? (
          <div className="allSeccion">
            <div className="menuSeccion">
              <NavLink className="itemSeccion" to={props.data + "/nuevo"}>
                Nuevo
              </NavLink>
              <NavLink className="itemSeccion" to={props.data + "/listado"}>
                Listado
              </NavLink>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
