import React, { useEffect, useState } from "react";
import { useStore } from "../../store/appStore";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const CardServicios = (props) => {
  const [datos, setDatos] = useState();
  const navigate = useNavigate();
  const { clienteCurrent, loadServiciosFiltrados } = useStore();
  useEffect(() => {
    const cliente = props.id ? props.id : clienteCurrent.cif;
    loadServiciosFiltrados(cliente)
      .then((presupuestos) => {
        setDatos(presupuestos);
        console.log("Presupuestos filtrados:", presupuestos);
      })
      .catch((error) => {
        console.error("Error al cargar presupuestos:", error);
      });
  }, []);


  useEffect(()=>{
    console.log(datos)
  },[datos])
    return (
      <div className="datos">
        {datos?.length>0 ? 
          <div className="divCardPresupuestos">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <th style={{ textAlign: "left" }}>Entrega</th>
                  <th style={{ textAlign: "left" }}>Recogida</th>
                  <th style={{ textAlign: "left" }}>Tipo Cuba</th>
                </tr>
              </thead>
              <tbody>
                {datos.map((dato, index) => (
                  <tr
                    key={dato.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "white" : "#f2f2f2",
                      marginRight:10,
                      width:300
                    }}
                    onDoubleClick={() =>
                      navigate(`/ofertas/listado/${dato.id}`)
                    }
                  >
                    <td style={{width:150}}>
                      {moment(dato?.dateTimeDelivery?.toDate()).format("DD/MM/YYYY")}
                    </td>
                    <td style={{width:150}}>
                      {moment(dato?.dateTimePick?.toDate()).format("DD/MM/YYYY")}
                    </td>
                    <td style={{width:150}}>{dato.tipoCuba}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
         : 
          <label
            style={{
              display: "flex",
              margin: "30px",
              fontSize: "20px",
            }}
          >
            No tiene Servicios asociados
          </label>
        }
      </div>
    );
};
