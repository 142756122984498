import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import jsPDF from "jspdf";
import moment from "moment";
import firma from "../img/firma.png";

const uploadFile = async (file, nombre) => {
  console.log(file, nombre);
  try {
    const storageRef = ref(storage, nombre);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    //alert("Condiciones actualizadas");
    console.log(url);
    return url;
  } catch (error) {
    console.log(error);
  }
};

const generateAndUploadPDF = async (
  clienteCurrent,
  firmaCliente,
  firmaRecogida,
  fotoEntrega,
  fotoRecogida
) => {
  console.warn(clienteCurrent);
  try {
    // Crear el documento PDF
    const doc = new jsPDF();

    // Agregar título
    doc.setFont("bold", 18);

    doc.text("CONTRATO DE ALQUILER DE CUBA/CONTENEDOR", 37, 25);
    const titleX = 36;
    const titleY = 10;
    const titleWidth = doc.getTextWidth(
      " CONTRATO DE ALQUILER DE CUBA/CONTENEDOR "
    );
    const titleHeight = 18; // Altura de la fuente
    doc.setLineWidth(0.5); // Grosor de la línea
    doc.line(
      titleX,
      titleY + titleHeight + 1,
      titleX + titleWidth,
      titleY + titleHeight + 1
    ); // Dibujar línea bajo el título

    // Agregar contenido
    doc.setFontSize(12);
    doc.setLineHeightFactor(1.5);
    const p1 = `De una parte Doña Estefanía Rodríguez Pérez, mayor de edad, con D.N.I. 47.012064-H y en representación de la entidad Hermanos Rodríguez Siles, S.L. con C.I.F. B-41961996, con domicilio a efectos de notificación en P.I. El Pilero C/Cordeleros, 15 de Carmona y como ARRENDADOR.`;
    const p2 = `De otra parte ${clienteCurrent.nombreCliente}, con C.I.F. ${clienteCurrent.idCliente}, domicilio a efectos de notificación en  ${clienteCurrent.direCliente} de ${clienteCurrent.locaCliente}, número de teléfono  ${clienteCurrent.telCliente}, y como ARRENDATARIO/A.`;
    const p3 =
      "Ambas partes se reconocen mutuamente tener capacidad legal necesaria para el otorgamiento del presente contrato que se estipula con las siguientes cláusulas:";
    const p4 =
      "PRIMERA.- El arrendatario/a, de conformidad con lo establecido en el art. 1555 del Código Civil, es responsable del correcto uso del contenedor/cuba durante el periodo de arrendamiento.";
    const p5 =
      "SEGUNDA.- El arrendatario se obliga a no llenar el contenedor por encima del límite representado por sus aristas superiores, ni a colocar ningún suplemento en los laterales. La carga no podrá sobrepasar, en ningún caso, los bordes del contenedor/cuba.";
    const p6 =
      "TERCERA.- El arrendatario será responsable de conformidad con lo establecido en el art. 138.1c de la LOTT y ordenanzas municipales de cualquier infracción de la normativa sobre excesos de peso o llenado del contenedor/cuba.";
    const p7 =
      "CUARTA.- Queda terminantemente prohibido depositar en el contenedor residuos tóxicos, peligrosos o reactivos, asumiendo la responsabilidad el arrendatario productor de los residuos de conformidad con la Ley 22/2011, de 28 de julio, de residuos y suelos contaminantes.";
    const p8 =
      "QUINTA.- El arrendatario es responsable de la correcta ubicación y señalización del contenedor/cuba, que deberá colocarse en la línea exterior formada por los vehículos correctamente estacionados. En caso de que fuera necesario instalar el contenedor/cuba en zona en la que no está permitido el estacionamiento, el arrendatario deberá estar en posesión de la correspondiente licencia municipal.";
    const p9 =
      "SEXTA.- El contenedor/cuba, una vez lleno, deberá ser retirado, para lo cual, el arrendatario deberá avisar a la empresa transportista con la debida antelación respondiendo, en su caso, en las sanciones correspondientes.";
    const p10 =
      "SÉPTIMA.- La empresa transportista solo pondrá el contenedor en propiedades privadas, por petición expresa del cliente, no haciéndose cargo de los daños que se pudieran producir en el pavimento por colocación del contenedor/cuba en zonas privadas.";
    const p11 =
      "OCTAVA.- El arrendatario se hará cargo de los posibles daños producidos en el contenedor durante el uso del mismo.";
    const p12 =
      "NOVENA.- El Ayuntamiento podrá ordenar la retirada de los contenedores directamente a la empresa arrendadora, aún cuando cuenten con autorización, si las circunstancias medioambientales, de circulación o celebración de eventos autorizados lo aconsejen. Haciéndose cargo de la totalidad del servicio por parte del arrendatario, sin ninguna bonificación o descuento por los días de uso o contenido del mismo.";
    const p13 =
      "DÉCIMA.- El productor/a o, en caso de acopio de materiales de construcción, la persona física o jurídica beneficiaria de la obra, deberá proceder a la limpieza del espacio ocupado por el contenedor o cuba al efectuar las sustituciones o retirada de los mismos.";
    const p14 =
      "UNDÉCIMA.- Los contenedores que se instalen deberán cumplir lo preceptuado en la Ordenanza de Limpieza de Espacios Públicos y de Gestión de Residuos.";
    const p15 =
      "DUODÉCIMA.- La duración máxima del servicio de arrendamiento será de 5 días laborables para las cubas de 4 metros cúbicos, 3 días laborables para las cubas de 1 metro cúbico y 10 días laborables para las cubas de 8 metros cúbicos.";
    const p16 =
      "DÉCIMA TERCERA.- Si la duración del arrendamiento sobrepasara el límite establecido en el punto anterior, se agravará con un recargo del 20% sobre su precio base."; // Agregar el texto al documento en múltiples líneas
    const p17 =
      "DÉCIMA CUARTA.- El aviso de retirada de la cuba/contenedor se hará exclusivamente al teléfono 696288789, con una antelación mínima de 3 horas. No haciéndose responsable la empresa de cualquier siniestro o multa que se produzca sin haberse producido dicho aviso en el tiempo de preaviso establecido.";
    const p18a = "ARRENDADOR";
    const p18b = "ARRENDATARIO/A";
    const p19 = `Carmona,  ${moment().format("dddd D [de] MMMM [del] YYYY")}`;
    const p20 = `HERMANOS RODRIGUEZ SILES S.L. `;

    doc.setLineHeightFactor(1.5);
    const p21 = `P.I. El Pilero`;
    const p22 = "C/ Cordeleros Nº15";
    const p23 = "41410 Carmona (Sevilla)";
    const p24 = "Tlf. 696288789";
    const p25 = `Datos cliente:`;
    const p26 = `Cif: ${clienteCurrent.idCliente}`;
    const p27 = `Nombre: ${clienteCurrent.nombreCliente}`;
    const p28 = `Dirección: ${clienteCurrent.direCliente}, ${clienteCurrent.locaCliente} `;
    const p29 = `Datos del Servicio:`;
    const p30 = `Tipo cuba: ${clienteCurrent.tipoCuba}`;
    const p31 = `Direccion del servicio: ${clienteCurrent.direContactoServicio}`;
    const p32 = `Localidad: ${clienteCurrent.localidadContactoServicio}`;
    const p33 = `Encargado de obra`;
    const p34 = `  Nombre: ${clienteCurrent.nombreContactoServicio}`;
    const p35 = `  Dni: ${clienteCurrent.dniContactoServicio}`;
    const p36 = `  telefono: ${clienteCurrent.tlfContactoServicio}`;
    const p37 = `Datos de la entrega:`;
    const p38 = `Fecha: ${moment(
      clienteCurrent.dateTimeDelivery.toDate()
    ).format("DD-MM-YYYY HH:mm")}`;
    const p39 = `Chofer: ${clienteCurrent.nombreChoferDelivery}`;
    const p40 = `Direccion: ${clienteCurrent.direContactoDelivery}`;
    const p41 = `Localidad: ${clienteCurrent.localidadContactoDelivery}`;
    const p42 = `Persona que recepciona`;
    const p43 = `  Nombre: ${clienteCurrent.nombreContactoDelivery}`;
    const p44 = `  Dni: ${clienteCurrent.dniContactoDelivery}`;
    const p45 = `  telefono: ${clienteCurrent.tlfContactoDelivery}`;
    const p46 = `Datos de la recogida:`;
    const p47 = `Fecha: ${moment(clienteCurrent.dateTimePick?.toDate()).format(
      "DD-MM-YYYY HH:mm"
    )}`;
    const p48 = `Chofer: ${clienteCurrent.nombreChoferPick}`;
    const p49 = `Direccion: ${clienteCurrent.direContactoPick}`;
    const p50 = `Localidad: ${clienteCurrent.localidadContactoPick}`;
    const p51 = `Persona que entrega`;
    const p52 = `  Nombre: ${clienteCurrent.nombreContactoPick}`;
    const p53 = `  Dni: ${clienteCurrent.dniContactoPick}`;
    const p54 = `  telefono: ${clienteCurrent.tlfContactoPick}`;

    doc.setFontSize(10);
    doc.text(doc.splitTextToSize(p1, 160), 25, 45);
    doc.text(doc.splitTextToSize(p2, 160), 25, 65);
    doc.text(doc.splitTextToSize(p3, 160), 25, 85);
    doc.text(doc.splitTextToSize(p4, 160), 25, 100);
    doc.text(doc.splitTextToSize(p5, 160), 25, 115);
    doc.text(doc.splitTextToSize(p6, 160), 25, 135);
    doc.text(doc.splitTextToSize(p7, 160), 25, 155);
    doc.text(doc.splitTextToSize(p8, 160), 25, 175);
    doc.text(doc.splitTextToSize(p9, 160), 25, 200);
    doc.text(doc.splitTextToSize(p10, 160), 25, 220);
    doc.text(doc.splitTextToSize(p11, 160), 25, 240);
    doc.addPage();
    doc.text(doc.splitTextToSize(p12, 160), 25, 30);
    doc.text(doc.splitTextToSize(p13, 160), 25, 60);
    doc.text(doc.splitTextToSize(p14, 160), 25, 80);
    doc.text(doc.splitTextToSize(p15, 160), 25, 95);
    doc.text(doc.splitTextToSize(p16, 160), 25, 115);
    doc.text(doc.splitTextToSize(p17, 160), 25, 130);
    doc.addImage(firma, 110, 145, 72, 47);
    doc.addImage(firmaCliente, "PNG", 30, 145, 72, 47, undefined, "FAST");
    doc.text(doc.splitTextToSize(p18a, 160), 25, 195);
    doc.text(doc.splitTextToSize(p18b, 160), 135, 195);
    doc.text(doc.splitTextToSize(p19, 160), 25, 200);
    doc.addPage();
    doc.setFontSize(18);
    doc.text("INFORMACION DEL SERVICIO", 60, 25);
    doc.setLineWidth(0.5); // Grosor de la línea
    doc.line(
      titleX,
      titleY + titleHeight + 1,
      titleX + titleWidth,
      titleY + titleHeight + 1
    ); // Dibujar línea bajo el título

    // Agregar contenido
    doc.setFontSize(14);
    doc.text(doc.splitTextToSize(p20, 160), 15, 45);
    doc.setFontSize(10);
    doc.text(doc.splitTextToSize(p21, 160), 15, 50);
    doc.text(doc.splitTextToSize(p22, 160), 15, 55);
    doc.text(doc.splitTextToSize(p23, 160), 15, 60);
    doc.text(doc.splitTextToSize(p24, 160), 15, 65);
    doc.setFontSize(14);
    doc.text(doc.splitTextToSize(p25, 160), 120, 75);
    doc.setFontSize(10);
    doc.text(doc.splitTextToSize(p26, 160), 120, 80);
    doc.text(doc.splitTextToSize(p27, 160), 120, 85);
    doc.text(doc.splitTextToSize(p28, 160), 120, 90);
    doc.setFontSize(14);
    doc.text(doc.splitTextToSize(p29, 160), 15, 75);
    doc.setFontSize(10);
    doc.text(doc.splitTextToSize(p30, 160), 15, 80);
    doc.text(doc.splitTextToSize(p31, 160), 15, 85);
    doc.text(doc.splitTextToSize(p32, 160), 15, 90);
    doc.text(doc.splitTextToSize(p33, 160), 15, 95);
    doc.text(doc.splitTextToSize(p34, 160), 15, 100);
    doc.text(doc.splitTextToSize(p35, 160), 15, 105);
    doc.text(doc.splitTextToSize(p36, 160), 15, 110);
    doc.setFontSize(14);
    doc.text(doc.splitTextToSize(p37, 160), 15, 120);
    doc.setFontSize(10);
    doc.text(doc.splitTextToSize(p38, 160), 15, 125);
    doc.text(doc.splitTextToSize(p39, 160), 15, 130);
    doc.text(doc.splitTextToSize(p40, 160), 15, 135);
    doc.text(doc.splitTextToSize(p41, 160), 15, 140);
    doc.text(doc.splitTextToSize(p42, 160), 15, 145);
    doc.text(doc.splitTextToSize(p43, 160), 15, 150);
    doc.text(doc.splitTextToSize(p44, 160), 15, 155);
    doc.text(doc.splitTextToSize(p45, 160), 15, 160);
    if (clienteCurrent.dateTimePick) {
      doc.setFontSize(14);
      doc.text(doc.splitTextToSize(p46, 160), 15, 220);
      doc.setFontSize(10);
      doc.text(doc.splitTextToSize(p47, 160), 15, 225);
      doc.text(doc.splitTextToSize(p48, 160), 15, 230);
      doc.text(doc.splitTextToSize(p49, 160), 15, 235);
      doc.text(doc.splitTextToSize(p50, 160), 15, 240);
      doc.text(doc.splitTextToSize(p51, 160), 15, 245);
      doc.text(doc.splitTextToSize(p52, 160), 15, 250);
      doc.text(doc.splitTextToSize(p53, 160), 15, 255);
      doc.text(doc.splitTextToSize(p54, 160), 15, 260);
      doc.addImage(firmaRecogida, "PNG", 20, 265, 72, 47, undefined, "FAST");
      if (fotoRecogida !== "")
        doc.addImage(fotoRecogida, "PNG", 90, 220, 108, 70, undefined, "FAST");
    }
    doc.addImage(firmaCliente, "PNG", 20, 165, 72, 47, undefined, "FAST");
    doc.addImage(fotoEntrega, "PNG", 90, 120, 108, 70, undefined, "FAST");
    // eslint-disable-next-line no-unused-expressions

    // Agregar más contenido según sea necesario...

    // Guardar el documento como blob
    const blob = doc.output("blob");

    // Subir el archivo PDF a Firebase Storage utilizando la función personalizada
    const downloadURL = await uploadFile(blob, "contrato.pdf");
    if (downloadURL) {
      //alert("PDF generado y subido a Firebase correctamente");
      return downloadURL;
    } else {
      alert("Hubo un problema al subir el PDF a Firebase");
    }
  } catch (error) {
    // Mostrar un mensaje de error si algo falla
    console.error("Hubo un problema al generar o subir el PDF:", error);
    alert("Hubo un problema al generar o subir el PDF");
  }
};

export const enviando = async (fila) => {
  const firma = await fetch(fila.urlFirmaDelivery)
    .then((response) => response.blob())
    .then((blob) => {
      const firma = URL.createObjectURL(blob);
      return firma;
    });
  const firma2 = await fetch(fila.urlFirmaPick)
    .then((response) => response.blob())
    .then((blob) => {
      const firma = URL.createObjectURL(blob);
      return firma;
    });
  const foto = await fetch(fila.urlFotoDelivery)
    .then((response) => response.blob())
    .then((blob) => {
      const firma = URL.createObjectURL(blob);
      return firma;
    });
  let foto2 = "";
  if (fila.urlFotoPick !== "null") {
    foto2 = await fetch(fila.urlFotoPick)
      .then((response) => response.blob())
      .then((blob) => {
        const firma = URL.createObjectURL(blob);
        return firma;
      });
  }
  const link = await generateAndUploadPDF(fila, firma, firma2, foto, foto2);
  try {
    window.Email.send({
      SecureToken: "151273f6-6e64-4663-9b19-d486582dbccb",
      To: fila.mailCliente,
      From: "cubas@rodriguezsiles.es",
      Subject: "Notificación de servicio de cubas",
      Body: `A/A.- ${fila.nombreCliente}</br></br></br></br>
        Estimado cliente:</br></br></br>
        Le indicamos que su servicio de cuba  ${fila.tipoCuba}.  solicitado a la dirección ${fila.direContactoDelivery} de ${fila.localidadContactoDelivery} ha sido concluido.</br></br>
        A continuación el adjuntamos el contrato y la información del mismo.</br></br>
        Un saludo.`,
      Attachments: [
        {
          name: "contrato.pdf",
          path: link,
        },
      ],
    }).then(async (message) => {
      console.warn("mesaje", message);
    });
  } catch (error) {
    console.warn(error);
  }
};
