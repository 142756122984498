import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";

import { auth, db } from "../firebase";
import moment from "moment";

const authContext = createContext();

//////crar Contexto
export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

///////////////Funciones de Auth///////////////////

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };
  const logout = () => signOut(auth);
  const resetPassword = async (email) => sendPasswordResetEmail(auth, email);

  /////////////////////////// Actualizacion los datos de perfil
  const updateCurrentProfile = (displayName,photoURL) =>
    updateProfile(auth.currentUser, {
      displayName,photoURL
    });

  ///////////////////////Funciones de DB

  //crea un documanto en la coleccion

  const insert = async (tabla, data) => {
    try {
      const docRef = await addDoc(collection(db, tabla), data);
      console.log(tabla, "Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const insertCliente = async (data) => {
    try {
      const documentRef = doc(db, "clientes", data.cif);
      await setDoc(documentRef, data);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  ///compribar log
  async function consultarLogs(currentUser) {
    if (currentUser !== null) {
      const token = currentUser.refreshToken; // Reemplaza con el ID del documento que deseas comprobar

const documentRef = doc(db, 'logs', token);

try {
  const documentSnapshot = await getDoc(documentRef); // Intenta obtener el documento
  if (!documentSnapshot.exists()) {
            // La consulta no devolvió resultados, por lo que el usuario no existe en la colección "logs"
            const token = currentUser.refreshToken;
            const nombre =
              currentUser.displayName === null
                ? currentUser.email
                : currentUser.nombre;
            const email = currentUser.email;
            const entrada = moment().format("DD/MM/YYYY HH:mm");
            const data = { nombre, email, entrada };
            const documentRef = doc(db, "logs", token);

            // Utiliza setDoc solo si no hay resultados en la consulta
            setDoc(documentRef, data)
              .then(() => {
                console.log("Documento creado con éxito.");
              })
              .catch((error) => {
                console.error(
                  "Error al crear el documento en Firestore:",
                  error
                );
              });
          } else {
            console.log('Usuario ya existe en la colección "logs".');
          }
      } catch (error) {
        console.error("Error al consultar en Firestore:", error);
      }
    }
  }
  //saca datos de una coleccion
  const getDatos = (tabla) => getDocs(collection(db, tabla));

  //saca datos de un documento
  const getDato = async (tabla, documento) => {
    const noteSnapshot = await getDoc(doc(db, tabla, documento));
    if (noteSnapshot.exists()) {
      return noteSnapshot.data();
    } else {
      console.log("Note doesn't exist");
    }
  };
  //eliminar un documento
  const delDato = (tabla, documento) => deleteDoc(doc(db, tabla, documento));

  //actualiza documento
  const updateDato = (tabla, documento, data) =>
    updateDoc(doc(db, tabla, documento), data);

  useEffect(() => {
    const unsubuscribe = onAuthStateChanged(auth, (currentUser) => {
      consultarLogs(currentUser);
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubuscribe();
  }, []);

  return (
    <authContext.Provider
      value={{
        signup,
        login,
        user,
        logout,
        loading,
        resetPassword,
        updateCurrentProfile,
        insert,
        getDatos,
        getDato,
        delDato,
        updateDato,
        insertCliente,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
