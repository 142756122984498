import React, { useEffect } from "react";
import { useMemo } from "react";
import moment from "moment";
import { useStore } from "../../store/appStore";
import { Table } from "../table";

export default function ListadoLogs() {
  const { loadLogs, logsStore } = useStore();
  const data = useMemo(() => logsStore, [logsStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "idCliente" },
      { Header: "Nombre cliente", accessor: "nombre" },
      { Header: "Email", accessor: "email" },
      { Header: "Entrada", accessor: "entrada" },
      { Header: "Salida", accessor: "salida" },
    ],
    []
  );
  useEffect(() => {
    loadLogs();
  }, []);

  return (
    <>
      <Table columns={columns} data={data} />
    </>
  );
}
