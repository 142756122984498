import { useNavigate } from "react-router-dom";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { GlobalFilter } from "./globalFilter";
import Select from "react-select";
import { useAuth } from "../context/authContext";
import "../css/table.scss";
import { useState } from "react";
import { enviando } from "./contratoFinal";
import moment from "moment";

export const Table = ({ columns, data, filtrar }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtro, setFiltro] = useState({ value: "todos", label: "Todos" });
  const { updateDato } = useAuth();
  const navigate = useNavigate();
  const options = [
    { value: "todos", label: "Todos" },
    { value: "pendienteEntrega", label: "Pendientes entrega" },
    { value: "pendienteRecepcion", label: "Pendientes retirar" },
    { value: "pendienteCobro", label: "Pendintes de cobro" },
    { value: "cobrados", label: "Cobrados" },
    { value: "pendienteFacturar", label: "Pendintes de facturar" },
    { value: "facturados", label: "Facturados" },
    { value: "finalizados", label: "Finalizados" },
  ];

  const handleOpen = (e, id) => {
    if (e.detail === 2) {
      navigate(`${id}`);
    }
  };

  const handleClick = (e, id) => {
    e.stopPropagation();
    const isSelected = selectedRows.includes(id);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };
  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  return (
    <div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={state.globalFilter}
      />
      {filtrar ? (
        <div className="search">
          {selectedRows.length > 0 ? (
            <button
              className="button main"
              onClick={async () => {
                // Imprimir los IDs de las filas seleccionadas en la consola
                selectedRows.map(async (fila) => {
                  console.warn(fila.id)
                  // Ejecutar la función enviando con await
                  if (fila.dateTimeDelivery) {
                    await enviando(fila);
                    if (!fila.fechaEnvioDelivery)
                      await updateDato("servicios", fila.id, {
                        ...fila,
                        fechaEnvioDelivery: moment().format(
                          "YYYY-MM-DDTHH:mm:ssZ"
                        ),
                      });
                  }
                  if (!fila.fechaEnvioPick && fila.dateTimePick)
                    await updateDato("servicios", fila.id, {
                      ...fila,
                      fechaEnvioPick: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                    });

                  // Ejecutar la función updateDato y esperar a que se complete
                  //await updateDato("servicios", idCubas, cubasCurrent);
                  console.warn("fila", fila);
                });

                // Esperar a que todas las promesas se resuelvan
                alert("Se han enviado todos los correos");
              }}
            >
              Enviar
            </button>
          ) : null}
          <label className="label">Filtro:</label>

          <Select
            styles={{ width: "300px" }}
            className="select"
            classNamePrefix="selectClass"
            value={filtro}
            options={options}
            onChange={(val) => setFiltro(val)}
          />
        </div>
      ) : null}
      <table id="customers" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {filtrar ? <th>Enviar</th> : null}
              {headerGroup.headers.map((column, id) => {
                if (id !== 0)
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▽"
                            : " △"
                          : ""}
                      </span>
                    </th>
                  );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows
            .filter((row) => {
              switch (filtro.value) {
                case "pendienteEntrega":
                  return !row.original.nombreContactoDelivery;
                case "pendienteRecepcion":
                  return row.original.nombreContactoPick === "";
                case "finalizados":
                  return (
                    row.original.nombreContactoPick !== "" &&
                    row.original.nombreContactoPick
                  );
                case "pendienteCobro":
                  return row.original.cobrado === false;
                case "cobrados":
                  return row.original.cobrado === true;
                case "pendienteFacturar":
                  return row.original.facturado === false;
                case "facturados":
                  return row.original.facturado === true;
                default:
                  return true;
              }
            })
            .map((row) => {
              prepareRow(row);
              return (
                <tr
                  className={
                    row.original.activada &&
                    row.original.nombreContactoPick === ""
                      ? "recogida"
                      : row.original.activadaEntrega &&
                        row.original.nombreContactoDelivery === ""
                      ? "entrega"
                      : "normal"
                  }
                  {...row.getRowProps()}
                  onClick={(e) => handleOpen(e, row.original.id)}
                >
                  {filtrar ? (
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => handleClick(e, row.original)}
                      />
                    </td>
                  ) : null}
                  {row.cells.map((cell, idx) => {
                    if (idx !== 0)
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
