import React, { useEffect } from "react";
import { useMemo } from "react";
import { useStore } from "../../store/appStore";
import { Table } from "../table";


export default function ListadoCubas() {
  const {loadCubas,cubasStore} = useStore();
  const data = useMemo(() => cubasStore, [cubasStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Tamaño", accessor: "tamaño" },
      { Header: "Cantidad", accessor: "cantidad" },
    ],
    []
  );
  useEffect(() => {
    loadCubas()
  }, []);
  return <Table columns={columns} data={data} />;
}
