import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";

export default function FormCubas(props) {
  const { cubasCurrent, setCubas, cargarCubas,  } =
    useStore();
  const { delDato, updateDato, insert } = useAuth();
  const [enable, setEnable] = useState(false);

  const { idCubas } = useParams();
  const navigate = useNavigate();
  const handleChange = ({ target: { value, name } }) => {
    if (name === "cantidad" ){
      value = value.replace(/[^\d,.]/g, "");
    }
    setCubas({ ...cubasCurrent, [name]: value });
  };
  //

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (cubasCurrent?.tamaño) {
          try {
            const idCubas = await insert("tiposCubas", cubasCurrent);
            navigate(`/cubas/listado/${idCubas}`);
          } catch (err) {}
        } else {
          showToast("error", "Al menos debe rellenar el tamaño");
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al cliente?"
        );
        if (confirmDelete) {
          setCubas({});
          try {
            await delDato("tiposCubas", idCubas);
            navigate("/cubas/listado");
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al cliente?"
        );
        if (confirmUpdate && cubasCurrent?.tamaño) {
          try {
            await updateDato("tiposCubas", idCubas, cubasCurrent);
            navigate("/cubas/listado");
          } catch (err) {
            console.log(err);
          }
        } else if (confirmUpdate) {
          showToast(
            "error",
            "Al menos debe rellenar el nombre fiscal , seleccionar una serie de factura y una forma de pago"
          );
        }
        break;
      case "Editar":
        setEnable(true);
        break;
      default:
        break;
    }
  };
  async function datos() {
    if (idCubas) {
      await cargarCubas(idCubas);
      if (cubasCurrent === null) {
        showToast("error", "La cuba no existe");
        navigate("/cubas/listado");
      }
    }
  }

  useEffect(() => {
    datos();
  }, []);

  return (
    <>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="superior">
            <label className="tituloVentana">
              {idCubas ? "Modificar Cubas" : "Añadir Cubas"}
            </label>
            <div className="buttonMain">
              {idCubas ? (
                enable ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <input
                    className="button main"
                    type="submit"
                    value="Editar"
                    onClick={handleSubmit}
                  />
                )
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Añadir"
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
          <div className={idCubas ? (enable ? "enabled" : "disabled") : "enabled"}>
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label className="label">Tamaño</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="tamaño"
                    name="tamaño"
                    idCubas="tamaño"
                    onChange={handleChange}
                    value={cubasCurrent?.tamaño ? cubasCurrent?.tamaño : ""}
                  />
                </div>
                <div className="grupo">
                  <label className="label">Cantidad de cubas</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Cantidad"
                    name="cantidad"
                    idCubas="cantidad"
                    onChange={handleChange}
                    value={cubasCurrent?.cantidad ? cubasCurrent?.cantidad : 0}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
