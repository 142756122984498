import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Select from "react-select";
import { enviando } from "../contrato";

export default function FormServicios(props) {
  const { insert } = useAuth();
  const [servicio, setServicio] = useState();
  const {
    clientesStore,
    loadClientes,
    cargarCliente,
    clienteCurrent,
    cubasStore,
    loadCubas,
    choferStore,
    loadChofer,
  } = useStore();
  const navigate = useNavigate();

  const handleChange = ({ target: { value, name } }) => {
    if (name === "tlfContactoServicio" || name === "cp") {
      value = value.replace(/[^\d,.]/g, "");
    }
    setServicio({ ...servicio, [name]: value });
  };

  

  const handleSubmit = async (e) => {
    enviando(clienteCurrent,servicio);
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        console.warn(clienteCurrent);
        if (servicio.idCliente) {
          try {
            const idCubas = await insert("servicios", servicio);
            //console.warn(idCubas)
            navigate(`/servicios/listado/${idCubas}`);
          } catch (err) {}
        } else {
          showToast("error", "Debes de seleccionar un cliente");
        }
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    loadClientes();
    loadCubas();
    loadChofer();
  }, []);
  useEffect(() => {
    console.warn(servicio)
  }, [servicio])
  

  return (
    <>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="superior">
            <label className="tituloVentana">Añadir Servicio</label>
            <div className="buttonMain">
              <input
                className="button main"
                type="submit"
                value="Añadir"
                onClick={handleSubmit}
              />
            </div>
          </div>
          <div className={"enabled"}>
            <label
              className="label"
              style={{ fontSize: "20px", fontWeight: "bolder" }}
            >
              Datos de cliente
            </label>
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label htmlFor="" className="label">
                    Cliente
                  </label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={
                        servicio?.idCliente
                          ? clientesStore.find(
                              (cliente) => cliente.id === servicio?.idCliente
                            )
                          : null
                      }
                      options={clientesStore}
                      getOptionLabel={(cliente) => cliente.nombre}
                      getOptionValue={(cliente) => cliente.id}
                      onChange={(val) => {
                        cargarCliente(val.id);
                        setServicio({
                          ...servicio,
                          idCliente: val.id,
                          nombreCliente: val.nombre,
                          direCliente:val.direccion,
                          locaCliente:val.localidad,
                          telCliente:val.telefono ? val.telefono : null,
                          mailCliente: val.mail ? val.mail : null,
                          nombreContactoDelivery: "",
                          cobrado: false,
                          facturado: false,
                          activada: false,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="grupo">
                  <label className="label">Cif</label>
                  <label className="label">
                    {clienteCurrent?.cif
                      ? clienteCurrent?.cif
                      : servicio?.idCliente}
                  </label>
                </div>
                <div className="grupo">
                  <label className="label">Nombre</label>
                  <label className="label">
                    {clienteCurrent?.nombre
                      ? clienteCurrent?.nombre
                      : servicio?.nombreCliente}
                  </label>
                </div>
                <div className="grupo">
                  <label className="label">Telefono</label>
                  <label className="label">{clienteCurrent?.tlf}</label>
                </div>
                <div className="grupo">
                  <label className="label">E-Mail</label>
                  <label className="label">{clienteCurrent?.mail}</label>
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label className="label">Direccion</label>
                  <label className="label">{clienteCurrent?.direccion}</label>
                </div>
                <div className="grupo">
                  <label className="label">Localidad</label>
                  <label className="label">{clienteCurrent?.localidad}</label>
                </div>
                <div className="grupo">
                  <label className="label">Provincia</label>
                  <label className="label">{clienteCurrent?.provincia}</label>
                </div>
                <div className="grupo">
                  <label className="label">Código postal</label>
                  <label className="label">{clienteCurrent?.cp}</label>
                </div>
              </div>
            </div>
            <label
              c
              className="label"
              style={{ fontSize: "20px", fontWeight: "bolder" }}
            >
              Datos del Servicio
            </label>
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label htmlFor="" className="label">
                    Cuba
                  </label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={
                        servicio?.tipoCuba
                          ? cubasStore.find(
                              (cuba) => cuba.id === servicio?.tipoCuba
                            )
                          : null
                      }
                      options={cubasStore}
                      getOptionLabel={(cuba) => cuba.tamaño}
                      getOptionValue={(cuba) => cuba.id}
                      onChange={(val) => {
                        setServicio({
                          ...servicio,
                          idCuba: val.id,
                          tipoCuba: val.tamaño,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="grupo">
                  <label className="label">Dni</label>
                  <input
                    type="text"
                    className="text"
                    placeholder="DNI del responsable"
                    name="dniContactoServicio"
                    id="dniContactoServicio"
                    onChange={handleChange}
                    value={
                      servicio?.dniContactoServicio
                        ? servicio?.dniContactoServicio
                        : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">Nombre</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Nombre jefe de obra"
                    name="nombreContactoServicio"
                    id="nombreContactoServicio"
                    onChange={handleChange}
                    value={
                      servicio?.nombreContactoServicio
                        ? servicio?.nombreContactoServicio
                        : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">Apellidos</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="apellidos jefe de obra"
                    name="apellidosContactoServicio"
                    id="apellidosContactoServicio"
                    onChange={handleChange}
                    value={
                      servicio?.apellidosContactoServicio
                        ? servicio?.apellidosContactoServicio
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label htmlFor="" className="label">
                    Chofer
                  </label>
                  <div className="grupoSelect">
                    <Select
                      className="select"
                      classNamePrefix="selectClass"
                      isSearchable={true}
                      value={
                        servicio?.idChofer
                          ? choferStore.find(
                              (chofer) => chofer.id === servicio?.idChofer
                            )
                          : null
                      }
                      options={choferStore}
                      getOptionLabel={(chofer) => chofer.nombre}
                      getOptionValue={(chofer) => chofer.id}
                      onChange={(val) => {
                        setServicio({
                          ...servicio,
                          idChofer: val.id,
                          nombreChofer: val.nombre,
                          tokenChofer: val.token,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="grupo">
                  <label className="label">telefono</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Telefono"
                    name="tlfContactoServicio"
                    id="tlfContactoServicio"
                    onChange={handleChange}
                    value={
                      servicio?.tlfContactoServicio
                        ? servicio?.tlfContactoServicio
                        : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">Direccion del servicio</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Direccion del servicio"
                    name="direContactoServicio"
                    id="direContactoServicio"
                    onChange={handleChange}
                    value={
                      servicio?.direContactoServicio
                        ? servicio?.direContactoServicio
                        : ""
                    }
                  />
                </div>
                <div className="grupo">
                  <label className="label">Localidad del servicio</label>
                  <input
                    className="text"
                    type="text"
                    placeholder="Localidad del servicio"
                    name="localidadContactoServicio"
                    id="localidadContactoServicio"
                    onChange={handleChange}
                    value={
                      servicio?.localidadContactoServicio
                        ? servicio?.localidadContactoServicio
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
