import React, { useEffect } from "react";
import { useMemo } from "react";
import { useStore } from "../../store/appStore";
import { Table } from "../table";
import moment from "moment";

export default function ListadoServicios() {
  const { loadServicios, serviciosStore } = useStore();
  const data = useMemo(() => serviciosStore, [serviciosStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "idCliente" },
      { Header: "Nombre cliente", accessor: "nombreCliente" },
      { Header: "Cuba", accessor: "tipoCuba" },
      { Header: "Chofer", accessor: "nombreChofer" },
      { Header: "Dirección de entrega", accessor: "direContactoDelivery" },
      { Header: "Persona que recepciona", accessor: "nombreContactoDelivery" },
      {
        Header: "Telefono de quien recepciona",
        accessor: "tlfContactoDelivery",
      },
      {
        Header: "Fecha de entrega",
        accessor: "dateTimeDelivery",
        Cell: ({ value }) =>
          value ? moment(value.toDate()).format("DD-MM-YYYY") : null,
      },
      {
        Header: "Fecha de recogida",
        accessor: "dateTimeRecogida",
        Cell: ({ row }) => {
          try {
            const fechaEntrega = moment(row.original.dateTimeDelivery.toDate());
            const fechaRecogida = moment(row.original.dateTimePick.toDate());
            if (fechaRecogida.isAfter(fechaEntrega)) {
              return fechaRecogida.format("DD-MM-YYYY");
            }
            return null;
          } catch (error) {}
        },
      },
      {
        Header: "Cobrado",
        accessor: "cobrado",
        Cell: ({ value }) => (value ? "Cobrado" : "Pendiente"),
      },
      {
        Header: "Facturado",
        accessor: "facturado",
        Cell: ({ value }) => (value ? "Facturado" : "Pendiente"),
      },
      {
        Header: "Enviado",
        accessor: "enviado",
        Cell: ({ row }) => {
          try {
            if (row.original.fechaEnvioPick) return "Enviado Recogida";
            if (row.original.fechaEnvioDelivery) return "Enviado Entrega";
            return "Pendiente";
          } catch (error) {}
        },
      },
    ],
    []
  );
  useEffect(() => {
    loadServicios();
  }, []);

  return (
    <>
      <Table columns={columns} data={data} filtrar={true} />
    </>
  );
}
