import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { useStore } from "../../store/appStore";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

export default function FormServicios(props) {
  registerLocale("es", es);
  const {
    servicioCurrent,
    setServicios,
    cargarServicios,
    cargarCliente,
    setCliente,
    clienteCurrent,
    cubasStore,
    loadCubas,
    choferStore,
    loadChofer,
  } = useStore();
  const { idClienteSelected } = props;
  const { delDato, updateDato } = useAuth();
  const [enable, setEnable] = useState(false);
  const { idServicio } = useParams();
  const navigate = useNavigate();
  const [mostrarDatosServicio, setMostrarDatosServicio] = useState(false);
  const toggleMostrarDatosServicio = () => {
    setMostrarDatosServicio(!mostrarDatosServicio);
  };
  const [mostrarDatosEntrega, setMostrarDatosEntrega] = useState(false);
  const toggleMostrarDatosEntrega = () => {
    setMostrarDatosEntrega(!mostrarDatosEntrega);
  };
  const [mostrarDatosRecogida, setMostrarDatosRecogida] = useState(false);
  const toggleMostrarDatosRecogida = () => {
    setMostrarDatosRecogida(!mostrarDatosRecogida);
  };
  const [activoEntregaActual, setActivoEntregaActual] = useState();
  const [activoActual, setActivoActual] = useState();

  const envio = async () => {
    let requestBody = {};
    let enviar = false;
    console.warn("opcion1",activoEntregaActual !== servicioCurrent.activadaEntrega && servicioCurrent.activadaEntrega)
    console.warn("opcion",activoActual !== servicioCurrent.activada && servicioCurrent.activada)
    if (
      activoEntregaActual !== servicioCurrent.activadaEntrega &&
      servicioCurrent.activadaEntrega
    ) {
      enviar = true;
      requestBody = {
        to: servicioCurrent.tokenChofer,
        notification: {
          body: `Se le ha asignado una entrega de una cuba de ${servicioCurrent.tipoCuba} a ${servicioCurrent.nombreCliente}`,
          title: "Entrega Asignada",
        },
      };
    }
    if (activoActual !== servicioCurrent.activada && servicioCurrent.activada) {
      enviar = true;
      requestBody = {
        to: servicioCurrent.tokenChofer,
        notification: {
          body: `Se le ha asignado una recogida de una cuba de ${servicioCurrent.tipoCuba} a ${servicioCurrent.nombreCliente}`,
          title: "Recogida Asignada",
        },
      };
    }
    console.warn(requestBody)
    if (enviar)
      axios
        .post("https://fcm.googleapis.com/fcm/send", requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "key=AAAAJS8mltI:APA91bFdNpYgz7V2eyQjlPTwtyAQcfn7FIpULWgcEtBsMxwGuMrA7zdQcgpR0SZCq-QJ02x_c3dDNVmtEzlpboHtjVbcw78tRNocAvYHv6b4M0LNqk4L1ELk9uNTkRwikOanux1dmrw6",
          },
        })
        .then((response) => {
          console.warn('ok',response)
          // Manejar la respuesta exitosa aquí
        })
        .catch((error) => {
          console.warn('error',error)
          // Manejar el error de la solicitud aquí
        });
  };


  function isValidEmail(email) {
    const regex = /^(([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6})$)/;
    return regex.test(email);
  }

  const handleChange = ({ target: { value, name } }) => {
    
    if (
      name === "tlfContactoServicio" ||
      name === "tlfContactoDelivery" ||
      name === "tlfContactoPick"
    ) {
      value = value.replace(/[^\d,.]/g, "");
    }
    try {
      setServicios({ ...servicioCurrent, [name]: value });
    } catch (error) {}
    if (name==='mail')
    try {
      setCliente({ ...clienteCurrent, [name]: value });
    } catch (error) {}
    
  };

  //

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al cliente?"
        );
        if (confirmDelete) {
          setServicios({});
          try {
            await delDato("servicios", idServicio);
            navigate("/servicios/listado");
            break;
          } catch (error) {}
        }
        break;
      case "Modificar":
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar el servicio?"
        );
        if (confirmUpdate) {
          try {
            if (isValidEmail(clienteCurrent.mail) || clienteCurrent.mail===""){
            envio();
            await updateDato("servicios", idServicio, servicioCurrent);
            await updateDato("clientes", servicioCurrent.idCliente, clienteCurrent);
            navigate("/servicios/listado");
            }else{
              alert("El email proporcionado no es correcto, por favor reviselo o eliminelo");
            }
          } catch (err) {}
        } else if (confirmUpdate) {
          showToast(
            "error",
            "Algo salio mal a la hora de modificar el registro"
          );
        }
        break;
      case "Editar":
        setActivoEntregaActual(servicioCurrent.activadaEntrega);
        setActivoActual(servicioCurrent.activada);
        setEnable(true);
        break;
      default:
        break;
    }
  };
  async function datos() {
    if (idServicio) {
      try {
        cargarServicios(idServicio);
      } catch (error) {
        // Manejo de errores
      }
    }
  }
  async function datosCliente() {
    if (servicioCurrent === null) {
      showToast("error", "El cliente no existe");
      navigate("/clientes/listado");
    } else {
      try {
        await cargarCliente(servicioCurrent.idCliente);
      } catch (error) {
        // Manejo de errores
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await datos();
        await loadCubas();
        await loadChofer();
      } catch (error) {
        // Manejo de errores
      }
    }

    fetchData();
  }, []);
  useEffect(() => {
    if (!clienteCurrent?.localidad)
      datosCliente();
  }, [servicioCurrent]);

  return (
    <>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="superior">
            <label className="tituloVentana">
              {idServicio ? "Modificar Cliente" : "Añadir Cliente"}
            </label>
            <div className="buttonMain">
              {idServicio || idClienteSelected ? (
                enable ? (
                  <>
                    <input
                      className="button main"
                      type="submit"
                      value="Modificar"
                      onClick={handleSubmit}
                    />
                    <input
                      type="submit"
                      className="button main"
                      value="Eliminar"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <input
                    className="button main"
                    type="submit"
                    value="Editar"
                    onClick={handleSubmit}
                  />
                )
              ) : (
                <input
                  className="button main"
                  type="submit"
                  value="Añadir"
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
          <div className={"enabled"}>
            <label
              className="label"
              style={{ fontSize: "20px", fontWeight: "bolder" }}
            >
              Datos de cliente
            </label>
            <div className="datos">
              <div className="columna">
                <div className="grupo">
                  <label className="label">Cif</label>
                  <label className="label">
                    {clienteCurrent?.cif
                      ? clienteCurrent?.cif
                      : servicioCurrent.idCliente}
                  </label>
                </div>
                <div className="grupo">
                  <label className="label">Nombre</label>
                  <label className="label">
                    {clienteCurrent?.nombre
                      ? clienteCurrent?.nombre
                      : servicioCurrent.nombreCliente}
                  </label>
                </div>
                <div className="grupo">
                  <label className="label">Telefono</label>
                  <label className="label">{clienteCurrent?.tlf}</label>
                </div>
                <div className="grupo">
                  <label className="label">E-Mail</label>
                  <input
                    type="text"
                    className={
                      idServicio
                        ? enable
                          ? "enabled text"
                          : "disabled text"
                        : "enabled text"
                    }
                    placeholder="E-Mail del cliente"
                    name="mail"
                    id="mail"
                    onChange={handleChange}
                    value={clienteCurrent?.mail ? clienteCurrent?.mail : ""}
                  />
                </div>
              </div>
              <div className="columna">
                <div className="grupo">
                  <label className="label">Direccion</label>
                  <label className="label">{clienteCurrent?.direccion}</label>
                </div>
                <div className="grupo">
                  <label className="label">Localidad</label>
                  <label className="label">{clienteCurrent?.localidad}</label>
                </div>
                <div className="grupo">
                  <label className="label">Provincia</label>
                  <label className="label">{clienteCurrent?.provincia}</label>
                </div>
                <div className="grupo">
                  <label className="label">Código postal</label>
                  <label className="label">{clienteCurrent?.cp}</label>
                </div>
              </div>
            </div>
            <div>
              <label
                style={{ fontSize: "20px", fontWeight: "bolder" }}
                className="label"
                onClick={toggleMostrarDatosServicio}
              >
                Datos del servicio
                <span>{!mostrarDatosServicio ? " ▽" : " △"}</span>
              </label>
              {mostrarDatosServicio && (
                <div
                  className={
                    idServicio ? (enable ? "enabled" : "disabled") : "enabled"
                  }
                >
                  <div className="datos">
                    <div className="columna">
                      <div className="grupo">
                        <label htmlFor="" className="label">
                          Cuba
                        </label>
                        <div className="grupoSelect">
                          <Select
                            className="select"
                            classNamePrefix="selectClass"
                            isSearchable={true}
                            value={
                              servicioCurrent?.idCuba
                                ? cubasStore.find(
                                    (cuba) =>
                                      cuba.id === servicioCurrent?.idCuba
                                  )
                                : null
                            }
                            options={cubasStore}
                            getOptionLabel={(cuba) => cuba.tamaño}
                            getOptionValue={(cuba) => cuba.id}
                            onChange={(val) => {
                              setServicios({
                                ...servicioCurrent,
                                idCuba: val.id,
                                tipoCuba: val.tamaño,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="grupo">
                        <label className="label">Dni</label>
                        <input
                          type="text"
                          className="text"
                          placeholder="DNI persona responsable"
                          name="dniContactoServicio"
                          id="dniContactoServicio"
                          onChange={handleChange}
                          value={
                            servicioCurrent?.dniContactoServicio
                              ? servicioCurrent?.dniContactoServicio
                              : ""
                          }
                        />
                      </div>
                      <div className="grupo">
                        <label className="label">Nombre</label>
                        <input
                          className="text"
                          type="text"
                          placeholder="Nombre"
                          name="nombreContactoServicio"
                          id="nombreContactoServicio"
                          onChange={handleChange}
                          value={
                            servicioCurrent?.nombreContactoServicio
                              ? servicioCurrent?.nombreContactoServicio
                              : ""
                          }
                        />
                      </div>
                      <div className="grupo">
                        <label className="label">Apellidos</label>
                        <input
                          className="text"
                          type="text"
                          placeholder="Apellidos"
                          name="apellidosContactoServicio"
                          id="apellidosContactoServicio"
                          onChange={handleChange}
                          value={
                            servicioCurrent?.apellidosContactoServicio
                              ? servicioCurrent?.apellidosContactoServicio
                              : ""
                          }
                        />
                      </div>
                      <div className="grupo">
                        <label className="label">
                          Fecha de envio de la entrega
                        </label>
                        <label className="label">
                          {servicioCurrent?.fechaEnvioDelivery
                            ? servicioCurrent?.fechaEnvioDelivery
                            : "Pendiente de envio"}
                        </label>
                      </div>
                      <div className="grupo">
                        <label className="label">
                          Fecha de envio de la recogida
                        </label>
                        <label className="label">
                          {servicioCurrent?.fechaEnvioPick
                            ? servicioCurrent?.fechaEnvioPick
                            : "Pendiente de envio"}
                        </label>
                      </div>
                    </div>
                    <div className="columna">
                      <div className="grupo">
                        <label htmlFor="" className="label">
                          Chofer
                        </label>
                        <div className="grupoSelect">
                          <Select
                            className="select"
                            classNamePrefix="selectClass"
                            isSearchable={true}
                            value={
                              servicioCurrent?.idChofer
                                ? choferStore.find(
                                    (chofer) =>
                                      chofer.id === servicioCurrent?.idChofer
                                  )
                                : null
                            }
                            options={choferStore}
                            getOptionLabel={(chofer) => chofer.nombre}
                            getOptionValue={(chofer) => chofer.id}
                            onChange={(val) => {
                              setServicios({
                                ...servicioCurrent,
                                idChofer: val.id,
                                nombreChofer: val.nombre,
                                tokenChofer: val.token,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="grupo">
                        <label className="label">telefono</label>
                        <input
                          className="text"
                          type="text"
                          placeholder="Telefono"
                          name="tlfContactoServicio"
                          id="tlfContactoServicio"
                          onChange={handleChange}
                          value={
                            servicioCurrent?.tlfContactoServicio
                              ? servicioCurrent?.tlfContactoServicio
                              : ""
                          }
                        />
                      </div>
                      <div className="grupo">
                        <label className="label">Direccion del servicio</label>
                        <input
                          className="text"
                          type="text"
                          placeholder="Direccion del servicio"
                          name="direContactoServicio"
                          id="direContactoServicio"
                          onChange={handleChange}
                          value={
                            servicioCurrent?.direContactoServicio
                              ? servicioCurrent?.direContactoServicio
                              : ""
                          }
                        />
                      </div>
                      <div className="grupo">
                        <label className="label">Localidad del servicio</label>
                        <input
                          className="text"
                          type="text"
                          placeholder="Localidad de entrega"
                          name="localidadContactoServicio"
                          id="localidadContactoServicio"
                          onChange={handleChange}
                          value={
                            servicioCurrent?.localidadContactoServicio
                              ? servicioCurrent?.localidadContactoServicio
                              : ""
                          }
                        />
                      </div>
                      <div className="grupo">
                        <label className="label">Cobrado</label>
                        <div className="text switchText">
                          <label className="switch">
                            <input
                              type="checkbox"
                              defaultChecked={servicioCurrent.cobrado}
                              checked={servicioCurrent.cobrado}
                              onClick={() => {
                                servicioCurrent.cobrado =
                                  !servicioCurrent.cobrado;
                                setServicios({
                                  ...servicioCurrent,
                                  cobrado: servicioCurrent.cobrado,
                                });
                              }}
                            />
                            <div className="slider round"></div>
                          </label>
                        </div>
                      </div>
                      <div className="grupo">
                        <label className="label">Facturado</label>
                        <div className="text switchText">
                          <label
                            className="switch"
                            style={{ marginRight: "20px" }}
                          >
                            <input
                              type="checkbox"
                              defaultChecked={servicioCurrent.facturado}
                              checked={servicioCurrent.facturado}
                              onClick={() => {
                                servicioCurrent.facturado =
                                  !servicioCurrent.facturado;
                                if (servicioCurrent.facturado)
                                  setServicios({
                                    ...servicioCurrent,
                                    facturado: servicioCurrent.facturado,
                                  });
                                else
                                  setServicios({
                                    ...servicioCurrent,
                                    facturado: servicioCurrent.facturado,
                                    dateInvoice: null,
                                  });
                              }}
                            />
                            <div className="slider round"></div>
                          </label>
                          {servicioCurrent.facturado ? (
                            <input
                              className="text"
                              type="text"
                              placeholder="Fecha y numero  de Factura"
                              name="fechaNumeroFactura"
                              id="fechaNumeroFactura"
                              onChange={handleChange}
                              value={
                                servicioCurrent?.fechaNumeroFactura
                                  ? servicioCurrent?.fechaNumeroFactura
                                  : ""
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <label
                style={{ fontSize: "20px", fontWeight: "bolder" }}
                className="label"
                onClick={toggleMostrarDatosEntrega}
              >
                Datos de entrega
                <span>{!mostrarDatosEntrega ? " ▽" : " △"}</span>
              </label>
              {mostrarDatosEntrega && (
                <div
                  className={
                    idServicio ? (enable ? "enabled" : "disabled") : "enabled"
                  }
                >
                  {servicioCurrent.nombreContactoDelivery === "" ? (
                    <div className="datos">
                      <div className="columna">
                        <div className="grupo">
                          <label className="label">Activada</label>
                          <div className="text switchText">
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={servicioCurrent.activadaEntrega}
                                checked={servicioCurrent.activadaEntrega}
                                onClick={() => {
                                  servicioCurrent.activadaEntrega =
                                    !servicioCurrent.activadaEntrega;
                                  setServicios({
                                    ...servicioCurrent,
                                    activadaEntrega:
                                      servicioCurrent.activadaEntrega,
                                  });
                                }}
                              />
                              <div className="slider round"></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {servicioCurrent.nombreContactoDelivery !== "" ? (
                    <>
                      <div className="datos">
                        <div className="columna">
                          <div className="grupo">
                            <label htmlFor="" className="label">
                              Chofer
                            </label>
                            <div className="grupoSelect disabled">
                              <input
                                type="text"
                                className="text disabled"
                                placeholder="Chofer que entrega"
                                name="nombreChoferDelivery"
                                id="nombreChoferDelivery"
                                onChange={handleChange}
                                value={
                                  servicioCurrent?.nombreChoferDelivery
                                    ? servicioCurrent?.nombreChoferDelivery
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="grupo">
                            <label className="label">Dni</label>
                            <input
                              type="text"
                              className="text"
                              placeholder="DNI persona recepciona"
                              name="dniContactoDelivery"
                              id="dniContactoDelivery"
                              onChange={handleChange}
                              value={
                                servicioCurrent?.dniContactoDelivery
                                  ? servicioCurrent?.dniContactoDelivery
                                  : ""
                              }
                            />
                          </div>
                          <div className="grupo">
                            <label className="label">Nombre</label>
                            <input
                              className="text"
                              type="text"
                              placeholder="Nombre fiscal"
                              name="nombreContactoDelivery"
                              id="nombreContactoDelivery"
                              onChange={handleChange}
                              value={
                                servicioCurrent?.nombreContactoDelivery
                                  ? servicioCurrent?.nombreContactoDelivery
                                  : ""
                              }
                            />
                          </div>
                          <div className="grupo">
                            <label className="label">Apellidos</label>
                            <input
                              className="text"
                              type="text"
                              placeholder="Nombre fiscal"
                              name="apellidosContactoDelivery"
                              id="apellidosContactoDelivery"
                              onChange={handleChange}
                              value={
                                servicioCurrent?.apellidosContactoDelivery
                                  ? servicioCurrent?.apellidosContactoDelivery
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="columna">
                          <div className="grupo">
                            <label className="label">telefono</label>
                            <input
                              className="text"
                              type="text"
                              placeholder="Telefono"
                              name="tlfContactoDelivery"
                              id="tlfContactoDelivery"
                              onChange={handleChange}
                              value={
                                servicioCurrent?.tlfContactoDelivery
                                  ? servicioCurrent?.tlfContactoDelivery
                                  : ""
                              }
                            />
                          </div>
                          <div className="grupo">
                            <label className="label">
                              Direccion de entrega
                            </label>
                            <input
                              className="text"
                              type="text"
                              placeholder="Direccion de entrega"
                              name="direContactoDelivery"
                              id="direContactoDelivery"
                              onChange={handleChange}
                              value={
                                servicioCurrent?.direContactoDelivery
                                  ? servicioCurrent?.direContactoDelivery
                                  : ""
                              }
                            />
                          </div>
                          <div className="grupo">
                            <label className="label">
                              Localidad de entrega
                            </label>
                            <input
                              className="text"
                              type="text"
                              placeholder="Localidad de entrega"
                              name="localidadContactoDelivery"
                              id="localidadContactoDelivery"
                              onChange={handleChange}
                              value={
                                servicioCurrent?.localidadContactoDelivery
                                  ? servicioCurrent?.localidadContactoDelivery
                                  : ""
                              }
                            />
                          </div>
                          <div className="grupo">
                            <label className="label">Fecha de entrega</label>
                            <DatePicker
                              dateFormat="dd/MM/yyyy HH:mm"
                              locale="es"
                              placeholderText="Fecha de la entrega"
                              selected={
                                servicioCurrent?.dateTimeDelivery
                                  ? typeof servicioCurrent?.dateTimeDelivery ===
                                      "string" ||
                                    servicioCurrent?.dateTimeDelivery instanceof
                                      Date
                                    ? servicioCurrent?.dateTimeDelivery
                                    : servicioCurrent?.dateTimeDelivery.toDate()
                                  : null
                              }
                              onChange={(dateTimeDelivery) =>
                                setServicios({
                                  ...servicioCurrent,
                                  dateTimeDelivery,
                                })
                              }
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="Hora"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        {servicioCurrent.urlFirmaDelivery !== "" ? (
                          <img
                            src={servicioCurrent.urlFirmaDelivery}
                            alt=""
                            style={{
                              marginRight: "10px",
                              height: "auto",
                              width: "400px",
                            }}
                          />
                        ) : null}
                        {servicioCurrent.urlFotoDelivery !== "" ? (
                          <img
                            src={servicioCurrent.urlFotoDelivery}
                            alt=""
                            style={{
                              marginRight: "10px",
                              height: "auto",
                              width: "400px",
                            }}
                          />
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </div>
            {servicioCurrent.nombreContactoDelivery !== "" ? (
              <div>
                <label
                  style={{ fontSize: "20px", fontWeight: "bolder" }}
                  className="label"
                  onClick={toggleMostrarDatosRecogida}
                >
                  Datos de recogida
                  <span>{!mostrarDatosRecogida ? " ▽" : " △"}</span>
                </label>

                {mostrarDatosRecogida && (
                  <div
                    className={
                      idServicio ? (enable ? "enabled" : "disabled") : "enabled"
                    }
                  >
                    {servicioCurrent.nombreContactoPick === "" ? (
                      <div className="datos">
                        <div className="columna">
                          <div className="grupo">
                            <label className="label">Activada</label>
                            <div className="text switchText">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  defaultChecked={servicioCurrent.activada}
                                  checked={servicioCurrent.activada}
                                  onClick={() => {
                                    servicioCurrent.activada =
                                      !servicioCurrent.activada;
                                    setServicios({
                                      ...servicioCurrent,
                                      activada: servicioCurrent.activada,
                                    });
                                    console.log(servicioCurrent);
                                  }}
                                />
                                <div className="slider round"></div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {servicioCurrent.nombreContactoPick !== "" ? (
                      <>
                        <div className="datos">
                          <div className="columna">
                            <div className="grupo">
                              <label htmlFor="" className="label">
                                Chofer
                              </label>
                              <div className="grupoSelect">
                                <Select
                                  className="select"
                                  classNamePrefix="selectClass"
                                  isSearchable={true}
                                  value={
                                    servicioCurrent?.idChoferPick
                                      ? choferStore.find(
                                          (chofer) =>
                                            chofer.id ===
                                            servicioCurrent?.idChoferPick
                                        )
                                      : null
                                  }
                                  options={choferStore}
                                  getOptionLabel={(chofer) => chofer.nombre}
                                  getOptionValue={(chofer) => chofer.id}
                                  onChange={(val) => {
                                    setServicios({
                                      ...servicioCurrent,
                                      idChoferPick: val.id,
                                      nombreChoferPick: val.nombre,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="grupo">
                              <label className="label">Dni</label>
                              <input
                                type="text"
                                className="text"
                                placeholder="DNI persona recepciona"
                                name="dniContactoPick"
                                id="dniContactoPick"
                                onChange={handleChange}
                                value={
                                  servicioCurrent?.dniContactoPick
                                    ? servicioCurrent?.dniContactoPick
                                    : ""
                                }
                              />
                            </div>
                            <div className="grupo">
                              <label className="label">Nombre</label>
                              <input
                                className="text"
                                type="text"
                                placeholder="Nombre fiscal"
                                name="nombreContactoPick"
                                id="nombreContactoPick"
                                onChange={handleChange}
                                value={
                                  servicioCurrent?.nombreContactoPick
                                    ? servicioCurrent?.nombreContactoPick
                                    : ""
                                }
                              />
                            </div>
                            <div className="grupo">
                              <label className="label">Apellidos</label>
                              <input
                                className="text"
                                type="text"
                                placeholder="Nombre fiscal"
                                name="apellidosContactoPick"
                                id="apellidosContactoPick"
                                onChange={handleChange}
                                value={
                                  servicioCurrent?.apellidosContactoPick
                                    ? servicioCurrent?.apellidosContactoPick
                                    : ""
                                }
                              />
                            </div>
                            <div className="grupo">
                              <label className="label">observaciones</label>
                              <textarea
                                style={{ height: "127px" }}
                                className="text"
                                type="text"
                                placeholder="Nombre fiscal"
                                name="observaciones"
                                id="observaciones"
                                onChange={handleChange}
                                value={
                                  servicioCurrent?.observaciones
                                    ? servicioCurrent?.observaciones
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="columna">
                            <div className="grupo">
                              <label className="label">telefono</label>
                              <input
                                className="text"
                                type="text"
                                placeholder="Telefono"
                                name="tlfContactoPick"
                                id="tlfContactoPick"
                                onChange={handleChange}
                                value={
                                  servicioCurrent?.tlfContactoPick
                                    ? servicioCurrent?.tlfContactoPick
                                    : ""
                                }
                              />
                            </div>
                            <div className="grupo">
                              <label className="label">
                                Direccion de recogida
                              </label>
                              <input
                                className="text"
                                type="text"
                                placeholder="Direccion de entrega"
                                name="direContactoPick"
                                id="direContactoPick"
                                onChange={handleChange}
                                value={
                                  servicioCurrent?.direContactoPick
                                    ? servicioCurrent?.direContactoPick
                                    : ""
                                }
                              />
                            </div>
                            <div className="grupo">
                              <label className="label">
                                Localidad de recogida
                              </label>
                              <input
                                className="text"
                                type="text"
                                placeholder="Localidad de entrega"
                                name="localidadContactoPick"
                                id="localidadContactoPick"
                                onChange={handleChange}
                                value={
                                  servicioCurrent?.localidadContactoPick
                                    ? servicioCurrent?.localidadContactoPick
                                    : ""
                                }
                              />
                            </div>
                            <div className="grupo">
                              <label className="label">Fecha de recogida</label>
                              <DatePicker
                                dateFormat="dd/MM/yyyy HH:mm"
                                locale="es"
                                placeholderText="Fecha de la recogida"
                                selected={
                                  servicioCurrent?.dateTimePick
                                    ? typeof servicioCurrent?.dateTimePick ===
                                        "string" ||
                                      servicioCurrent?.dateTimePick instanceof
                                        Date
                                      ? servicioCurrent?.dateTimePick
                                      : servicioCurrent?.dateTimePick.toDate()
                                    : null
                                }
                                onChange={(dateTimePick) =>
                                  setServicios({
                                    ...servicioCurrent,
                                    dateTimePick,
                                  })
                                }
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Hora"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          {servicioCurrent.urlFirmaPick !== "" ? (
                            <img
                              src={servicioCurrent.urlFirmaPick}
                              alt=""
                              style={{
                                marginRight: "10px",
                                height: "auto",
                                width: "400px",
                              }}
                            />
                          ) : null}
                          {servicioCurrent.urlFotoPick !== "" ? (
                            <img
                              src={servicioCurrent.urlFotoPick}
                              alt=""
                              style={{
                                marginRight: "10px",
                                height: "auto",
                                width: "400px",
                              }}
                            />
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </>
  );
}
