import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import Cargando from "../cargando/cargando";
import { useStore } from "../../store/appStore";
import Select from "react-select";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { CardPresupuestos } from "./cardPresupuestos";
import { CardServicios } from "./cardServicios";

export default function FormClientes(props) {
  const { clienteCurrent, cargarCliente, setCliente, choferStore } = useStore();
  const { idClienteSelected } = props;
  const { delDato, updateDato, insertCliente } = useAuth();
  const [enable, setEnable] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [cargando, setCargando] = useState(true);
  const handleChange = ({ target: { value, name } }) => {
    if (name === "tlf") {
      value = value.replace(/[^\d,.]/g, "");
    }
    setCliente({ ...clienteCurrent, [name]: value });
  };

  function isValidEmail(email) {
    const regex = /^(([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6})$)/;
    return regex.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accion = e.target.value;
    switch (accion) {
      case "Añadir":
        if (clienteCurrent?.nombre) {
          try {
            if (
              isValidEmail(clienteCurrent.mail) ||
              clienteCurrent.mail === ""
            ) {
              const id = await insertCliente(clienteCurrent);
              navigate(`/clientes/listado/${id}`);
            } else {
              alert(
                "El email proporcionado no es correcto, por favor reviselo o eliminelo"
              );
            }
          } catch (err) {}
        } else {
          showToast(
            "error",
            "Al menos debe rellenar el nombre fiscal , seleccionar una serie de factura y una forma de pago"
          );
        }
        break;
      case "Eliminar":
        const confirmDelete = window.confirm(
          "¿Seguro que desea eliminar al cliente?"
        );
        if (confirmDelete) {
          setCliente({});
          try {
            await delDato("clientes", id);
            navigate("/clientes/listado");
            break;
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case "Modificar":
        console.log(clienteCurrent);
        const confirmUpdate = window.confirm(
          "¿Seguro que desea modificar al cliente?"
        );
        if (confirmUpdate && clienteCurrent?.nombre) {
          console.log(clienteCurrent);
          try {
            if (
              isValidEmail(clienteCurrent.mail) ||
              clienteCurrent.mail === ""
            ) {
              await updateDato("clientes", id, clienteCurrent);
              navigate("/clientes/listado");
            } else {
              alert(
                "El email proporcionado no es correcto, por favor reviselo o eliminelo"
              );
            }
          } catch (err) {
            console.log(err);
          }
        } else if (confirmUpdate) {
          showToast("error", "Al menos debe rellenar el nombre");
        }
        break;
      case "Editar":
        setEnable(true);
        break;
      default:
        break;
    }
  };
  async function datos() {
    if (id) {
      await cargarCliente(id);
      if (clienteCurrent === null) {
        showToast("error", "El cliente no existe");
        navigate("/clientes/listado");
      }
    }

    setCargando(false);
  }

  const options = [
    { value: "cliente", label: "Cliente" },
    { value: "lead", label: "Lead" },
    { value: "interesado", label: "Interesado" },
    { value: "interesFuturo", label: "Interes Futuro" },
    { value: "sinInteres", label: "Sin interes" },
    { value: "antiguo", label: "Antiguo Cliente" },
  ];

  useEffect(() => {
    datos();
    setCargando(false);
  }, []);
  if (cargando) {
    <Cargando />;
  } else {
    return (
      <>
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="superior">
              <label className="tituloVentana">
                {id ? "Modificar Cliente" : "Añadir Cliente"}
              </label>
              <div className="buttonMain">
                {id || idClienteSelected ? (
                  enable ? (
                    <>
                      <input
                        className="button main"
                        type="submit"
                        value="Modificar"
                        onClick={handleSubmit}
                      />
                      <input
                        type="submit"
                        className="button main"
                        value="Eliminar"
                        onClick={handleSubmit}
                      />
                    </>
                  ) : (
                    <input
                      className="button main"
                      type="submit"
                      value="Editar"
                      onClick={handleSubmit}
                    />
                  )
                ) : (
                  <input
                    className="button main"
                    type="submit"
                    value="Añadir"
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </div>
            <div className={id ? (enable ? "enabled" : "disabled") : "enabled"}>
              <div className="datos">
                <div className="columna">
                  <div className="grupo">
                    <label htmlFor="" className="label">
                      Tipo Cliente
                    </label>
                    <div className="grupoSelect">
                      <Select
                        className="select"
                        classNamePrefix="selectClass"
                        isSearchable={true}
                        options={options}
                        value={
                          clienteCurrent?.tipoCliente
                            ? options.find(
                                (opcion) =>
                                  opcion.value ===
                                  clienteCurrent?.tipoCliente.value
                              )
                            : null
                        }
                        onChange={(val) =>
                          setCliente({ ...clienteCurrent, tipoCliente: val })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="columna"></div>
              </div>
              <div className="datos">
                <div className="columna">
                  <div className="grupo">
                    <label className="label">Identificación Fiscal</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="Identificación fiscal"
                      name="cif"
                      id="cif"
                      onChange={handleChange}
                      value={clienteCurrent?.cif ? clienteCurrent?.cif : ""}
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">Nombre</label>
                    <input
                      className="text"
                      type="text"
                      placeholder="Nombre fiscal"
                      name="nombre"
                      id="nombre"
                      onChange={handleChange}
                      value={
                        clienteCurrent?.nombre ? clienteCurrent?.nombre : ""
                      }
                    />
                  </div>
                </div>
                <div className="columna">
                  <div className="grupo">
                    <label className="label">telefono</label>
                    <input
                      className="text"
                      type="text"
                      placeholder="Telefono"
                      name="tlf"
                      id="tlf"
                      onChange={handleChange}
                      value={clienteCurrent?.tlf ? clienteCurrent?.tlf : ""}
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">E-Mail</label>
                    <input
                      className="text"
                      type="text"
                      placeholder="E-Mail"
                      name="mail"
                      id="mail"
                      onChange={handleChange}
                      value={clienteCurrent?.mail ? clienteCurrent?.mail : ""}
                    />
                  </div>
                </div>
              </div>
              <div className="datos">
                <div className="columna">
                  <div className="grupo">
                    <label className="label">Dirección</label>
                    <input
                      className="text"
                      type="text"
                      placeholder="Dirección"
                      name="direccion"
                      id="direccion"
                      value={
                        clienteCurrent?.direccion
                          ? clienteCurrent?.direccion
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">Código Postal</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="CP"
                      name="cp"
                      id="cp"
                      value={clienteCurrent?.cp ? clienteCurrent?.cp : ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="columna">
                  <div className="grupo">
                    <label className="label">Localidad</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="Localidad"
                      name="localidad"
                      id="localidad"
                      value={
                        clienteCurrent?.localidad
                          ? clienteCurrent?.localidad
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grupo">
                    <label className="label">Provincia</label>
                    <input
                      type="text"
                      className="text"
                      placeholder="Provincia"
                      name="provincia"
                      id="provincia"
                      value={
                        clienteCurrent?.provincia
                          ? clienteCurrent?.provincia
                          : ""
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <Tabs className="react-tabs">
            <TabList className="react-tabs__tab-list">
              <Tab className="react-tabs__tab">Oferta</Tab>
              <Tab className="react-tabs__tab">Servicios</Tab>
            </TabList>
            <TabPanel className="react-tabs__tab-panel">
              <CardPresupuestos  id={id} />
            </TabPanel>
            <TabPanel className="react-tabs__tab-panel">
              <CardServicios id={id} />
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  }
}
