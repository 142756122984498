import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useStore } from "../../store/appStore";
import { Table } from "../table";


export default function ListadoCliente() {
  const {loadClientes,clientesStore} = useStore();
  const data = useMemo(() => clientesStore, [clientesStore]);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Tipo Cliente", accessor: "tipoCliente.label" },
      { Header: "CIF/DNI", accessor: "cif" },
      { Header: "Nombre", accessor: "nombre" },
      { Header: "Telefono", accessor: "tlf" },
      { Header: "E-Mail", accessor: "mail" },
      { Header: "Dirección", accessor: "direccion" }
    ],
    []
  );
  useEffect(() => {
    loadClientes()
  }, []);
  return <Table columns={columns} data={data} />;
}
