import { create } from "zustand";
import { db } from "../firebase";
import {
  collection,
  getDoc,
  doc,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
async function dato(table, document) {
  const documento = await getDoc(doc(db, table, document));
  if (documento.exists()) {
    return documento.data();
  } else {
    console.log("Note doesn't exist", table, document);
  }
}

export const useStore = create((set, get) => ({
  //Array con los datos del firestore
  clientesStore: [],
  cubasStore: [],
  serviciosStore: [],
  choferStore: [],
  mensajesStore: [],
  logsStore: [],
  ofertasStore: [],

  //datos del objeto actual
  clienteCurrent: {},
  choferCurrent: {},
  cubaCurrent: {},
  servicioCurrent: {},
  mensajeCurrent: {},
  ofertaCurrent: {},

  //limpiar Current
  limpiarCurrent: () => {
    set((state) => ({
      clienteCurrent: {},
      ofertasCurrent: {},
      choferCurrent: {},
      cubasCurrent: {},
      servicioCurrent: {},
    }));
  },

  //ModificaStore
  setCliente: (clienteCurrent) => set({ clienteCurrent }),
  setMensaje: (mensajeCurrent) => set({ mensajeCurrent }),
  setCubas: (cubasCurrent) => set({ cubasCurrent }),
  setServicios: (servicioCurrent) => set({ servicioCurrent }),
  setChofer: (choferCurrent) => set({ choferCurrent }),
  setSession: (sessionCurrent) => set({ sessionCurrent }),
  setOferta: (ofertaCurrent) => set({ ofertaCurrent }),
  //Funciones cargan colecciones
  loadOfertas: async () => {
    const unsubscribe = onSnapshot(collection(db, "ofertas"), (snapshot) => {
      const ofertasStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ ofertasStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadPresupuestosFiltrados: async (clienteId) => {
    let q;

    q = query(
      collection(db, "ofertas"),
      where("idCliente", "==", `${clienteId}`)
    );

    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    const presupuestos = [];
    querySnapshot.forEach((doc) => {
      presupuestos.push({ id: doc.id, ...doc.data() });
    });
    return presupuestos;
  },
  loadServiciosFiltrados: async (clienteId) => {
    let q;
    q = query(collection(db, "servicios"), where("idCliente", "==", clienteId));
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    const servicios = [];
    querySnapshot.forEach((doc) => {
      servicios.push({ id: doc.id, ...doc.data() });
    });
    return servicios;
  },
  loadLicencias: async (clienteId, licencia) => {
    let q;
    q = query(
      collection(db, "licencias"),
      where("cliente", "==", clienteId),
      where("licencia", "==", licencia)
    );
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);
    const servicios = [];
    querySnapshot.forEach((doc) => {
      servicios.push({ id: doc.id, ...doc.data() });
    });
    return servicios;
  },
  loadClientes: async () => {
    const unsubscribe = onSnapshot(collection(db, "clientes"), (snapshot) => {
      const clientesStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ clientesStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadMensajes: async () => {
    const unsubscribe = onSnapshot(collection(db, "mensajes"), (snapshot) => {
      const mensajesStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ mensajesStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },
  loadLogs: async () => {
    const unsubscribe = onSnapshot(collection(db, "logs"), (snapshot) => {
      const logsStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ logsStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadChofer: async () => {
    const unsubscribe = onSnapshot(collection(db, "chofers"), (snapshot) => {
      const choferStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ choferStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadCubas: async () => {
    const unsubscribe = onSnapshot(collection(db, "tiposCubas"), (snapshot) => {
      const cubasStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ cubasStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  loadServicios: async () => {
    const unsubscribe = onSnapshot(collection(db, "servicios"), (snapshot) => {
      const serviciosStore = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      set({ serviciosStore });
    });

    // Devolver la función de unsubscribe para desuscribirse cuando sea necesario
    return unsubscribe;
  },

  //funcionaes cargan documentos
  cargarCliente: async (id) => {
    const clienteCurrent = await dato("clientes", id);
    if (clienteCurrent) {
      set((state) => ({
        ...state,
        clienteCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        clienteCurrent: null,
      }));
    }
  },
  cargarOferta: async (id) => {
    const ofertaCurrent = await dato("ofertas", id);
    if (ofertaCurrent) {
      set((state) => ({
        ...state,
        ofertaCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        ofertaCurrent: null,
      }));
    }
  },
  cargarChofer: async (id) => {
    const choferCurrent = await dato("chofers", id);
    if (choferCurrent) {
      set((state) => ({
        ...state,
        choferCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        choferCurrent: null,
      }));
    }
  },

  cargarCubas: async (id) => {
    const cubasCurrent = await dato("tiposCubas", id);
    if (cubasCurrent) {
      set((state) => ({
        ...state,
        cubasCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        cubasCurrent: null,
      }));
    }
  },
  cargarServicios: async (id) => {
    const servicioCurrent = await dato("servicios", id);
    if (servicioCurrent) {
      set((state) => ({
        ...state,
        servicioCurrent,
      }));
    } else {
      set((state) => ({
        ...state,
        servicioCurrent: null,
      }));
    }
  },
}));
