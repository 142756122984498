import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.svg";
import { useAuth } from "../context/authContext";
import { showToast } from "../utils/utils";

export function Login() {
  console.log("first")
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [reset, setReset] = useState(false);

  const { login, resetPassword } = useAuth();
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    type: "",
    message: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!reset) {
      try {
        await login(user.email, user.password)
        .then((userCredential) => {
          console.log(userCredential)
          // Obtener el token de acceso del usuario
          const user = userCredential.user;
          return user.getIdToken();
        })
        .catch((error) => {
          // Manejar errores de inicio de sesión
          console.log(error);
        });
        navigate("/");
      } catch (error) {
        setAlert({ message: error.message, type: "error" });
      }
      showToast(alert.type, alert.message)
    } else {
      if (!user.email) return setAlert("Write an email to reset password");
      try {
        await resetPassword(user.email);
        setAlert({
          message: "Le hemos enviado un correo para resetear la password",
          type: "success",
        });
        setReset(false);
      } catch (error) {
        setAlert({ message: error.message, type: "error" });
      }
    }
    showToast(alert.type, alert.message)
  };
  const handleChange = ({ target: { value, name } }) =>
    setUser({ ...user, [name]: value });

  const resetAction = () => setReset((current) => !current);

  return (
    <div className="w-full max-w-xs m-auto text-black pt-6">
      <form
        onSubmit={handleSubmit}
        className=" shadow-md rounded px-8 pt-6 pb-6 mb-4"
        style={{ background: "#d19f26" }}
      >
        <img src={logo} alt="Logo" />
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Intoducca su email"
          />
          {reset ? null : (
            <div className="div-pass">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Intoducca su password"
              />
              
            </div>
          )}
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            {reset ? "Resetear" : "Iniciar"}
          </button>
        </div>
      </form>
      {/*<p className="my-4 text-sm flex justify-between px-3">
        {reset ? "Recuerdas tu contraeña?" : "¿Has olvidado tu contraseña?"}
        <button style={{ color: "blue" }} onClick={resetAction}>
          {reset ? "Volver a Login" : "Resetear contraseña"}
        </button>
          </p>*/}
    </div>
  );
}
